/* eslint-disable no-void */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import './signup.scss';
import { APP_ROUTE, Strings } from '@app/constants';
import { useDispatch } from 'react-redux';
import { checkEmail, getAnnoucments, signup } from '@app/modules/auth/slice';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Stack, MenuItem, IconButton, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { TextBox } from '@app/components/common/text-box';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from "@material-ui/core/styles";
import { Logo } from '@app/components/common/logo';
import { Footer } from '@app/components/common/footer';
import { Video } from '@app/components/common/video';
import { SmallContainer } from '@app/components/common/small-container';
import { enqueueSnackbar } from 'notistack';
import Input from '@app/components/common/Input';
import Iconify from '@app/components/common/iconify/Iconify';

const useStyles = makeStyles({
    root: {
        width: 200,
        "& .MuiOutlinedInput-input": {
            color: "green"
        },
        "& .MuiInputLabel-root": {
            color: "green"
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "green"
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "red"
        },
        "&:hover .MuiInputLabel-root": {
            color: "red"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "purple"
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "purple"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "purple"
        }
    }
});

const FSignUpScreen: React.FC = () => {
    const [selectedCountry, setCountry] = useState({ value: 'United States', error: '' });
    const [selectState, setState] = useState({ value: '', error: '' });
    const [first, setFirstVal] = useState({ value: '', error: '' });
    const [last, setLastVal] = useState({ value: '', error: '' });
    const [email, setEmailVal] = useState({ value: '', error: '' });
    const [cell, setCellVal] = useState({ value: '', error: '' });
    const [city, setCityVal] = useState({ value: '', error: '' });
    const [selectAge, setAgeState] = useState({ value: '', error: '' });
    const [term, setTerm] = useState({ value: '', error: '' });
    const [isLoading, setisLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [annoucement, setAnnoucement] = useState('');
    const [username, setUsername] = useState('');
    const navigate = useNavigate();
    const [createPassword, setCreatePasswordState] = useState({
        value: '',
        error: '',
    });
    const [confirmPassword, setConfirmPasswordState] = useState({
        value: '',
        error: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [orgName, setOrgVal] = useState({ value: '', error: '' });

    const disptach = useDispatch();
    // const { id } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    const navigator = useNavigate();

    const [loading, setLoading] = useState(false);
    const handleClick = () => {
        setLoading(true);
    }

    function handleChange(event: any, name: string): void {
        console.log('change', name, event.target.value);

        if (isChurch() || isBusiness()) {
            if (name === 'Org') {
                setOrgVal((prevState) => ({
                    ...prevState,
                    value: event.target.value,
                    error: '',
                }));
            }
        }

        if (name === 'First') {
            setFirstVal((prevState) => ({
                ...prevState,
                value: event.target.value,
                error: '',
            }));
        } else if (name === 'Last') {
            setLastVal((prevState) => ({
                ...prevState,
                value: event.target.value,
                error: '',
            }));
        } else if (name === 'Email') {
            setEmailVal((prevState) => ({
                ...prevState,
                value: event.target.value,
                error: '',
            }));
        } else if (name === 'Cell') {
            setCellVal((prevState) => ({
                ...prevState,
                value: event.target.value,
                error: '',
            }));
        } else if (name === 'City') {
            setCityVal((prevState) => ({
                ...prevState,
                value: event.target.value,
                error: '',
            }));
        } else if (name === 'Country') {
            let newCountry = event.target.value;
            setCountry((prevState) => ({
                ...prevState,
                value: newCountry,
                error: '',
            }));

            if (newCountry != 'United States') {
                setState({
                    ...selectState,
                    value: 'Not In The USA',
                });
            }
        } else if (name === 'State') {
            setState((prevState) => ({
                ...prevState,
                value: event.target.value,
                error: '',
            }));
        } else if (name === 'Create Password') {
            setCreatePasswordState((prevState) => ({
                ...prevState,
                value: event.target.value,
                error: '',
            }));
        } else if (name === 'Confirm Password') {
            setConfirmPasswordState((prevState) => ({
                ...prevState,
                value: event.target.value,
                error: '',
            }));
        } else if (name === 'Age') {
            setAgeState((prevState) => ({
                ...prevState,
                value: event.target.checked,
                error: '',
            }));
        } else if (name === 'Term') {
            setTerm((prevState) => ({
                ...prevState,
                value: event.target.checked,
                error: '',
            }));
        }
    }

    const setEmailError = (s: string) => {
        setEmailVal((prevState) => ({
            ...prevState,
            error: s,
        }));
    }

    function phoneBlur(event: any) {
        console.log('phone blur', cell.value);
        let nc = cell.value.replaceAll('e', '');

        setCellVal((prevState) => ({
            ...prevState,
            error: '',
            value: nc,
        }));

        if (cell.value && cell.value.length > 0) {
            if (selectedCountry.value == 'United States') {
                if (cell.value.trim().length != 10) {
                    setCellVal((prevState) => ({
                        ...prevState,
                        error: 'Mobile Number should have 10 digits.',
                    }));
                }
            }
        }
    }

    function emailBlur(event: any) {

        console.log('email blur', email.value);

        setEmailVal((prevState) => ({
            ...prevState,
            error: '',
        }));

        if (email.value === '') {
            setEmailVal((prevState) => ({
                ...prevState,
                error: 'Required Field.',
            }));
        }

        if (email.value) {
            let ev = email.value.trim();
            if (!ev.includes('@') || ev.includes(' ') || !ev.includes('.') || ev.endsWith('.') || ev.includes('@.') || ev.startsWith('@')) {
                setEmailVal((prevState) => ({
                    ...prevState,
                    error: 'Invalid Email.',
                }));
            }
        }

        disptach(
            checkEmail({
                email: email.value,
                setEmailError: setEmailError,
            })
        );
    }

    const setSignUpError = (s: string) => {
        if (s == 'User Already in Branch!') {
            setEmailVal((prevState) => ({
                ...prevState,
                error: 'This email is already in the system and cannot be used a second time. If you have questions, email members@freedomplace.com.  We are in BETA and will respond promptly.  Freedom Place.',
            }));
        }
        else {
            enqueueSnackbar(s, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
    }

    function onSubmit(): void {
        //setisLoading(true);
        let isError = false;
        setErrorMessage('');
        const passwordPattern = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

        if (isChurch() || isBusiness()) {
            setOrgVal((prevState) => ({
                ...prevState,
                error: '',
            }));
        }

        setFirstVal((prevState) => ({
            ...prevState,
            error: '',
        }));
        setLastVal((prevState) => ({
            ...prevState,
            error: '',
        }));
        setCellVal((prevState) => ({
            ...prevState,
            error: '',
        }));
        setCityVal((prevState) => ({
            ...prevState,
            error: '',
        }));
        setEmailVal((prevState) => ({
            ...prevState,
            error: '',
        }));

        if (isChurch() || isBusiness()) {
            if (orgName.value === '') {
                setOrgVal((prevState) => ({
                    ...prevState,
                    error: 'Required Field.',
                }));
                isError = true;
            }
        }

        if (first.value === '') {
            setFirstVal((prevState) => ({
                ...prevState,
                error: 'Required Field.',
            }));
            isError = true;
        }
        if (last.value === '') {
            setLastVal((prevState) => ({
                ...prevState,
                error: 'Required Field.',
            }));
            isError = true;
        }

        if (email.value === '') {
            setEmailVal((prevState) => ({
                ...prevState,
                error: 'Required Field.',
            }));
            isError = true;
        }

        if (email.value) {
            let ev = email.value.trim();
            if (!ev.includes('@') || ev.includes(' ') || !ev.includes('.') || ev.endsWith('.') || ev.includes('@.') || ev.startsWith('@')) {
                setEmailVal((prevState) => ({
                    ...prevState,
                    error: 'Invalid Email.',
                }));
                isError = true;
            }
        }

        if (cell.value === '') {
            setCellVal((prevState) => ({
                ...prevState,
                error: 'Required Field.',
            }));
            isError = true;
        }

        if (cell.value && cell.value.length && cell.value.trim().length < 6) {
            setCellVal((prevState) => ({
                ...prevState,
                error: 'Phone must have at least 6 numbers.',
            }));
            isError = true;
        }

        if (city.value === '') {
            setCityVal((prevState) => ({
                ...prevState,
                error: 'Required Field.',
            }));
            isError = true;
        }
        if (selectedCountry.value === '') {
            setCountry((prevState) => ({
                ...prevState,
                error: 'Required Field.',
            }));
            isError = true;
        }
        if (selectState.value === '') {
            setState((prevState) => ({
                ...prevState,
                error: 'Required Field.',
            }));
            isError = true;
        }
        if (
            createPassword.value === '' ||
            !passwordPattern.test(createPassword.value)
        ) {
            setCreatePasswordState((prevState) => ({
                ...prevState,
                error: !passwordPattern.test(createPassword.value)
                    ? 'Invalid Password'
                    : 'Required Field.',
            }));
            isError = true;
        }
        if (confirmPassword.value === '') {
            setConfirmPasswordState((prevState) => ({
                ...prevState,
                error: 'Required Field.',
            }));
            isError = true;
        }
        if (selectAge.value === '' || selectAge.value === 'false') {
            setAgeState((prevState) => ({
                ...prevState,
                error: 'Sorry, you are not authorized to sign up unless you are 18 years of age or older',
            }));
            isError = true;
        }
        if (term.value === '' || term.value === 'false') {
            setTerm((prevState) => ({
                ...prevState,
                error: 'Sorry, you are not authorized to sign up unless you agree with the Term Of Use and Communication Policy',
            }));
            isError = true;
        }
        if (
            confirmPassword.value !== '' &&
            createPassword.value !== confirmPassword.value
        ) {
            setConfirmPasswordState((prevState) => ({
                ...prevState,
                error: 'Passwords Do Not Match',
            }));
            isError = true;
        }
        if (!isError) {
            let the_name = '';
            if (isChurch() || isBusiness()) the_name = orgName.value;

            disptach(
                signup({
                    firstName: first.value,
                    lastName: last.value,
                    email: email.value.toLowerCase().trim(),
                    password: createPassword.value,
                    cellPhone: cell.value,
                    country: selectedCountry.value,
                    city: city.value,
                    consumerLinkID: id,
                    refererName: username,
                    regionOrState: selectState.value,
                    role: getRole(),
                    ageAndTerms: 'true',
                    businessName: the_name,
                    navigator,
                    setisLoading,
                    setErrorMessage: setSignUpError,
                }),
            );
        }
    }

    function isChurch() {
        let u = window.location.href.toLowerCase();
        return u.includes('notforprofit');
    }

    function isBusiness() {
        let u = window.location.href.toLowerCase();
        return u.includes('business');
    }

    function isConsumer() {
        let u = window.location.href.toLowerCase();
        return u.includes('consumer');
    }

    function getRole() {
        if (isChurch()) return Strings.Roles.NONPROFIT;
        if (isBusiness()) return Strings.Roles.BUSINESS;
        if (isConsumer()) return Strings.Roles.CONSUMER;

        return '';
    }

    useEffect(() => {
        if (id === undefined || id == null) {
            navigator(APP_ROUTE.SESSIONERROR);
        }
        console.log(isLoading);
        disptach(
            getAnnoucments({
                linkid: id,
                role: getRole(),
                setisLoading,
                navigate,
                setAnnoucement,
                setUsername,
            }),
        );
    }, []);

    const countryList = Strings.signUp.countryList;
    const stateList = Strings.signUp.stateList;
    const classes = useStyles();

    return (
        <>
            <Helmet>
                <title>Sign Up - Freedom Place</title>
                <style>
                    {
                        'body { background-color: black; }'
                    }
                </style>
            </Helmet>

            <Container sx={{
                paddingBottom: '40px',
                maxWidth: '1400px !important',
                position: 'relative',
                overflow: 'hidden',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
            }}>

                <Video />

                <Grid container justifyContent="center" sx={{
                    width: '100%',
                }}>
                    <SmallContainer>
                        <Logo />

                        <Stack className='d-none d-md-block mt-3' sx={{
                            width: '70%',
                        }}>
                            <div>1. Join For Free</div>
                            <div>2. Get Your Unique Trackable Link</div>
                            <div>3. Pass It On</div>
                            <div>4. Together... We Take Back What Is Ours</div>
                        </Stack>

                        <Stack className='d-block d-md-none mt-2' sx={{
                            width: '90%',
                            fontSize: '0.8em',
                        }}>
                            <div>1. Join For Free</div>
                            <div>2. Get Your Unique Trackable Link</div>
                            <div>3. Pass It On</div>
                            <div>4. Together... We Take Back What Is Ours</div>
                        </Stack>


                        <Stack spacing={3} sx={{
                            borderRadius: '20px',
                            marginTop: '30px',
                            marginBottom: '30px',
                            width: '100%',
                            padding: '5px 20px',
                            backgroundColor: '#212B36',
                        }}>
                            <Box>
                                You are signing up on the 1st wave of:
                            </Box>

                            <Grid container spacing={2} sx={{
                                width: '100%',
                                marginTop: '0px !important',
                            }}>
                                <Grid item xs={6}>
                                    <h6>{username}</h6>
                                    <h6 style={{
                                        borderRadius: '8px',
                                        backgroundColor: 'white',
                                        color: 'black',
                                        marginTop: '5px',
                                        display: 'inline-block',
                                        padding: '3px 5px',
                                        fontSize: '16px',
                                    }}>
                                        {getRole()}
                                    </h6>
                                </Grid>

                                <Grid item xs={6} sx={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    paddingBottom: '10px',
                                }}>
                                    <Link to="https://youtu.be/IOtu5CeaZIU" target='_blank' style={{
                                        color: 'white',
                                        paddingLeft: '10px',
                                    }}>
                                        <span className="mr-2" style={{ color: 'white' }}>Learn More</span>
                                        <img src="/assets/img/youtube.png" style={{
                                            height: '32px',
                                        }} />
                                    </Link>
                                </Grid>
                            </Grid>
                        </Stack>

                        <Stack spacing={2}>

                            {
                                isChurch() && (
                                    <Stack>
                                        <TextBox label="Org Name"
                                            value={orgName.value}
                                            onChange={(event) => {
                                                handleChange(event, 'Org');
                                                return void 0;
                                            }} />

                                        {
                                            orgName.error.length > 0 && (
                                                <div className='alert alert-danger'>{orgName.error}</div>
                                            )
                                        }
                                    </Stack>
                                )
                            }

                            {
                                isBusiness() && (
                                    <Stack>
                                        <TextBox label="Business"
                                            value={orgName.value}
                                            onChange={(event) => {
                                                handleChange(event, 'Org');
                                                return void 0;
                                            }} />

                                        {
                                            orgName.error.length > 0 && (
                                                <div className='alert alert-danger'>{orgName.error}</div>
                                            )
                                        }
                                    </Stack>
                                )
                            }

                            <Stack direction="row" style={{
                                marginLeft: '-15px',
                                marginRight: '-15px',
                            }}>


                                {/* Country */}

                                <div className='col'>
                                    <Stack>
                                        <TextBox variant="outlined" label="Country" select
                                            value={selectedCountry.value}
                                            onChange={(event) => {
                                                handleChange(event, 'Country');
                                                return void 0;
                                            }}
                                        >
                                            {countryList.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </TextBox>

                                        {
                                            selectedCountry.error.length > 0 && (
                                                <div className='alert alert-danger'>{selectedCountry.error}</div>
                                            )
                                        }

                                    </Stack>

                                </div>

                                {/* End Country */}

                                {/* State */}

                                <div className='col'>
                                    <Stack>
                                        <TextBox variant="outlined" label="State" select
                                            value={selectState.value}
                                            onChange={(event) => {
                                                handleChange(event, 'State');
                                                return void 0;
                                            }}
                                        >
                                            <MenuItem key="Select Your State" value="Select Your State">Select Your State</MenuItem>

                                            {stateList.map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </TextBox>
                                    </Stack>

                                </div>

                                {/* End State */}

                            </Stack>

                            <Stack>
                                <TextBox label="City" value={city.value}
                                    onChange={(event) => {
                                        handleChange(event, 'City');
                                        return void 0;
                                    }} />

                                {
                                    city.error.length > 0 && (
                                        <div className='alert alert-danger'>{city.error}</div>
                                    )
                                }
                            </Stack>

                            <Stack direction="row" spacing={3}>

                                {/* First Name */}

                                <Stack>

                                    <TextBox label="First Name" value={first.value}
                                        onChange={(event) => {
                                            handleChange(event, 'First');
                                            return void 0;
                                        }} />

                                    {
                                        first.error.length > 0 && (
                                            <div className='alert alert-danger'>{first.error}</div>
                                        )
                                    }

                                </Stack>

                                {/* End First Name */}

                                {/* Last Name */}

                                <Stack>
                                    <TextBox label="Last Name" value={last.value}
                                        onChange={(event) => {
                                            handleChange(event, 'Last');
                                            return void 0;
                                        }} />

                                    {
                                        last.error.length > 0 && (
                                            <div className='alert alert-danger'>{last.error}</div>
                                        )
                                    }
                                </Stack>

                                {/* End Last Name */}

                            </Stack>

                            {/* Email */}

                            <Stack>
                                <TextBox label="Email" value={email.value}
                                    onBlur={emailBlur}
                                    onChange={(event) => {
                                        handleChange(event, 'Email');
                                        return void 0;
                                    }} />

                                {
                                    email.error.length > 0 && (
                                        <div className='alert alert-danger'>{email.error}</div>
                                    )
                                }
                            </Stack>

                            {/* End Email */}

                            {/* Phone */}

                            <Stack>
                                <TextBox label="Mobile Number" value={cell.value} type='number'
                                    onKeyDown={(e) => {
                                        console.log('key', e);

                                        if (e.keyCode === 69 || e.which === 69 || e.key === 'e') {
                                            e.preventDefault(); e.stopPropagation();
                                        }
                                    }}
                                    onBlur={phoneBlur}
                                    onChange={(event) => {
                                        handleChange(event, 'Cell');
                                        return void 0;
                                    }} />

                                {
                                    cell.error.length > 0 && (
                                        <div className='alert alert-danger'>{cell.error}</div>
                                    )
                                }
                            </Stack>

                            {/* End Phone */}

                            {/* Password */}

                            <Stack>
                                <Input hintText='Password must be at least 8 characters and contain at least 1 (one) capital letter and 1 (one) number'
                                    label="Password" type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} color="white" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={createPassword.value}
                                    onChange={(event) => {
                                        handleChange(event, 'Create Password');
                                        return void 0;
                                    }}
                                >
                                </Input>

                                {
                                    createPassword.error.length > 0 && (
                                        <div className='alert alert-danger'>{createPassword.error}</div>
                                    )
                                }
                            </Stack>

                            {/* End Password */}

                            {/* Confirm Password */}

                            <Stack>
                                <TextBox label="Confirm Password" type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} color="white" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={confirmPassword.value}
                                    onChange={(event) => {
                                        handleChange(event, 'Confirm Password');
                                        return void 0;
                                    }}
                                >

                                </TextBox>

                                {
                                    confirmPassword.error.length > 0 && (
                                        <div className='alert alert-danger'>{confirmPassword.error}</div>
                                    )
                                }
                            </Stack>

                            {/* End Confirm Password */}

                            {/* Age */}

                            <Stack>
                                <FormControlLabel
                                    sx={{
                                        marginY: '0px !important',
                                    }}
                                    control={
                                        <Checkbox sx={{ color: 'white', }}
                                            value={selectAge.value}
                                            onChange={(event) => {
                                                handleChange(event, 'Age');
                                                return void 0;
                                            }}
                                        />
                                    }
                                    label="I'm over 18 years old"

                                />

                                {
                                    selectAge.error.length > 0 && (
                                        <div className='alert alert-danger'>{selectAge.error}</div>
                                    )
                                }
                            </Stack>

                            {/* End Age */}

                            <Stack
                                sx={{
                                    marginY: '0px !important',
                                }}>
                                <FormControlLabel
                                    sx={{
                                        marginY: '0px !important',
                                    }}
                                    control={
                                        <Checkbox sx={{ color: 'white', }}
                                            value={term.value}
                                            onChange={(event) => {
                                                handleChange(event, 'Term');
                                                return void 0;
                                            }} />
                                    }
                                    label={
                                        <div>
                                            I agree to the
                                            <Link to="https://www.freedomplace.com/about/terms" target='_blank' style={{
                                                color: 'white',
                                                paddingLeft: '10px'
                                            }}>Term Of Use</Link>
                                            <span> and</span>
                                            <Link to="https://www.freedomplace.com/about/privacy" target='_blank' style={{
                                                color: 'white',
                                                paddingLeft: '10px'
                                            }}>Communication Policy</Link>

                                        </div>
                                    } />

                                {
                                    term.error.length > 0 && (
                                        <div className='alert alert-danger'>{term.error}</div>
                                    )
                                }
                            </Stack>

                            <LoadingButton onClick={onSubmit} loading={isLoading} loadingPosition='end' variant="contained" sx={{
                                backgroundColor: 'white !important',
                                color: 'black !important',
                                fontWeight: 'bold !important',
                                fontSize: '1em !important',
                            }}>
                                <span>Sign Up</span>
                            </LoadingButton>

                            <Box sx={{
                                backgroundColor: '#212B36',
                                borderRadius: '20px',
                                padding: '20px',
                                marginBottom: '30px',
                            }}>
                                Once you select &quot;sign up&quot;, you will need to verify your mobile number, and email. If you have a problem, email members@freedomplace.com
                                or call (972) 646-1731
                            </Box>

                            <Grid container justifyContent="center">
                                <Link to="/Account/Login" style={{
                                    color: 'white',
                                    paddingLeft: '10px'
                                }}> &lt; Sign in instead</Link>
                            </Grid>

                        </Stack>
                    </SmallContainer>
                </Grid>

                <Footer />

            </Container >

        </>
    );
};

export default FSignUpScreen;
