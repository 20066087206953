import { endpoints, request } from '@app/api';
import { all, call, takeLatest } from 'redux-saga/effects';
import { admindata } from './slice';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* getAdminData({ payload }: ReturnType<typeof admindata>): any {
  //   const loader = createLoader();
  try {
    // yield put(loader.present());
    const { branch, sort, direction, setTableData, setPages, pageSize } = payload;
    // setisLoading(true);
    //endpoints.get_admin_data + branch,
    let url = `${endpoints.get_admin_data_2}?branch=${branch}&sort=${sort}&direction=${direction}`;
    const result = yield call(request.get, url);

    var pages = [];
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    for (let i = 0; i < result.length; i += pageSize) {
      const page = result.slice(i, i + 10);
      pages.push(page);
    }

    setPages([...pages]);
    setTableData(result);
  } catch (error: any) {
    // payload.setisLoading(false);
    // payload.setEmailError(error.message);
  } finally {
    // yield put(loader.dismiss());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* adminSagas() {
  yield all([takeLatest(admindata, getAdminData)]);
}

export { adminSagas };
