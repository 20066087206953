import { Grid } from "@mui/material";

const BigContainer = (props: any) => {
    return (
        <Grid container spacing={2} className='p-1 p-md-3'
            justifyContent="center" sx={{
                position: 'relative',
                marginTop: '40px',
                marginBottom: '40px',
                marginLeft: '10px',
                borderRadius: '20px',
                maxWidth: '1080px',
                color: 'white',
            }}>
            {props.children}
        </Grid>
    );
}
export { BigContainer }

