import { endpoints, request } from '@app/api';
import { APP_ROUTE, Strings } from '@app/constants';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  changePassword,
  getProfile,
  getUserWaves,
  updateInitialState,
  updateUserProfile,
} from './slice';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* updateUserProfileSaga({
  payload,
}: ReturnType<typeof updateUserProfile>) {
  //   const loader = createLoader();
  try {
    // yield put(loader.present());
    const {
      setisLoading,
      firstname,
      lastname,
      cellphone,
      city,
      stateOrRegion,
      email,
      country,
      businessName,
      uniqueLinkId,
      role,
      setUpdateMessage,
    } = payload;
    setisLoading(true);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    yield call(request.post, endpoints.update_profile, {
      firstName: firstname,
      lastName: lastname,
      cellPhone: cellphone,
      city,
      stateOrRegion,
      email,
      country,
      businessName,
      uniqueLinkId,
      branch: role,
    });
    setisLoading(false);
    yield put({
      type: Strings.Actions.UPDATE_USER,
      payload: {
        firstName: firstname,
        lastName: lastname,
        cellPhone: cellphone,
        country,
        city,
        regionOrState: stateOrRegion,
        email,
        businessName,
      },
    });
    setUpdateMessage('Profile Updated!');
    // yield call(showAlert, '', strings.success.password_reset_link_sent);
    // yield call(NavigationService.goBack);
  } catch (error: any) {
    payload.setisLoading(false);
    // payload.setEmailError(error.message);
    // showAlert('', error.message);
  } finally {
    // yield put(loader.dismiss());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* getUserProfileSaga({
  payload,
}: ReturnType<typeof updateUserProfile>) {
  //   const loader = createLoader();
  try {
    const { isConsumerApiLoading } = yield select((state) => state.user);

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!isConsumerApiLoading) {
      yield put({
        type: Strings.Actions.UPDATE_USER,
        payload: {
          isConsumerApiLoading: true,
        },
      });
      const {
        firstName,
        lastName,
        cellPhone,
        country,
        city,
        regionOrState,
        businessName,
      } = yield call(request.get, endpoints.get_profile);

      yield put({
        type: Strings.Actions.UPDATE_USER,
        payload: {
          firstName,
          lastName,
          cellPhone,
          country,
          city,
          regionOrState,
          businessName,
          isFetching: false,
          isConsumerApiLoading: false,
        },
      });
    }
  } catch (error: any) {
    console.log(error);
    if (payload.navigation !== undefined && error.status === 401) {
      yield put({
        type: Strings.Actions.DELETE_ALL,
        payload: {},
      });
      payload.navigation(APP_ROUTE.LOGIN);
    }
  } finally {
    // yield put(loader.dismiss());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* getUserWavesSaga(): ReturnType<any> {
  //   const loader = createLoader();
  try {
    const { isWaveApiLoading, linkId } = yield select((state) => state.user);

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!isWaveApiLoading) {
      yield put({
        type: Strings.Actions.UPDATE_USER,
        payload: {
          isWaveApiLoading: true,
        },
      });
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const results: any = yield call(
        request.get,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        endpoints.user_waves + linkId,
      );

      yield put({
        type: Strings.Actions.UPDATE_USER,
        payload: {
          isFetching: false,
          isWaveApiLoading: false,
          waveMetricsModels:
            results !== undefined && results !== null
              ? results[0]
              : {
                  signUpsPast30: 0,
                  signUpsPast60: 0,
                  signUpsPast90: 0,
                  signUpsToday: 0,
                  wave1_Past30: 0,
                  wave1_Past60: 0,
                  wave1_Past90: 0,
                  wave1_Today: 0,
                  wave2_Past30: 0,
                  wave2_Past60: 0,
                  wave2_Past90: 0,
                  wave2_Today: 0,
                  wave3_Past30: 0,
                  wave3_Past60: 0,
                  wave3_Past90: 0,
                  wave3_Today: 0,
                  wave4_Past30: 0,
                  wave4_Past60: 0,
                  wave4_Past90: 0,
                  wave4_Today: 0,
                },
        },
      });
    }
  } catch (error: any) {
    console.log(error);
    // if (payload.navigation !== undefined && error.status === 401) {
    //   yield put({
    //     type: Strings.Actions.DELETE_ALL,
    //     payload: {},
    //   });
    //   payload.navigation(APP_ROUTE.LOGIN);
    // }
  } finally {
    // yield put(loader.dismiss());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* changePasswordSaga({ payload }: ReturnType<typeof changePassword>) {
  //   const loader = createLoader();
  try {
    // yield put(loader.present());
    const {
      currentPassword,
      newPassword,
      setisLoading,
      setInputStateNewPassword,
      setInputStateConfirmPassword,
      setInputState,
      setUpdateMessage,
    } = payload;
    setisLoading(true);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    yield call(request.post, endpoints.change_password, {
      currentPassword,
      newPassword,
    });
    setisLoading(false);
    setInputStateNewPassword({ value: '', error: '' });
    setInputState({ value: '', error: '' });
    setInputStateConfirmPassword({ value: '', error: '' });
    setUpdateMessage('Password Changed SuccessFully!');
    // yield call(showAlert, '', strings.success.password_reset_link_sent);
    // yield call(NavigationService.goBack);
  } catch (error: any) {
    console.log('error', error);
    payload.setisLoading(false);
    payload.setError(error.message);
  } finally {
    // yield put(loader.dismiss());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* updateInitialStateSaga() {
  //   const loader = createLoader();
  try {
    yield put({
      type: Strings.Actions.UPDATE_USER,
      payload: {
        isFetching: true,
        errorMessage: '',
      },
    });
  } catch (error: any) {
  } finally {
    // yield put(loader.dismiss());
  }
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* userSagas() {
  yield all([
    takeLatest(updateUserProfile, updateUserProfileSaga),
    takeLatest(changePassword, changePasswordSaga),
    takeEvery(getProfile, getUserProfileSaga),
    takeLatest(updateInitialState, updateInitialStateSaga),
    takeLatest(getUserWaves, getUserWavesSaga),
  ]);
}

export { userSagas };
