/**
 * Header wrapper
 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTE, Strings } from '@app/constants';
import { useDispatch } from 'react-redux';
import { deleteAll } from '@app/modules/auth/slice';

interface Props {
  role: string;
  children: React.ReactNode;
}

const Header: React.FC<Props> = (props) => {
  const [name, setUrl] = useState<string | null>(null);
  const disptach = useDispatch();

  useEffect(() => {
    const urlName = window.location.pathname;
    setUrl(urlName);
  }, []);

  const navigation = useNavigate();
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function logout(name: string) {
    disptach(deleteAll({}));
    navigation(name);
  }
  return (
    // eslint-disable-next-line react/style-prop-object
    <nav
      className="navbar navbar-expand-md navbar-dark fixed-top bg-black"
      style={{ backgroundColor: 'black' }}
    >
      <img
        src={require('../assets/images/FP badge Small Freedom Place.png')}
        alt="te"
        height="25"
      />
      &nbsp;
      <a className="navbar-brand" href="#">
        {Strings.header.freedom}
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#myNavBar"
        aria-controls="myNavBar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="myNavBar">
        <ul className="navbar-nav mr-auto">
          <li
            className={`nav-item ${
              name === APP_ROUTE.DASHBOARDS.USER ||
              name === APP_ROUTE.DASHBOARDS.BUSINESS ||
              name === APP_ROUTE.DASHBOARDS.ADMIN ||
              name === APP_ROUTE.DASHBOARDS.NONPROFIT
                ? 'active'
                : ''
            }`}
          >
            <a
              className="nav-link"
              onClick={() => {
                if (props.role === Strings.Roles.CONSUMER) {
                  navigation(APP_ROUTE.DASHBOARDS.USER);
                } else if (props.role === Strings.Roles.BUSINESS) {
                  navigation(APP_ROUTE.DASHBOARDS.BUSINESS);
                } else if (
                  props.role === Strings.Roles.ADMIN ||
                  props.role === Strings.Roles.SUPERADMIN
                ) {
                  navigation(APP_ROUTE.DASHBOARDS.ADMIN);
                } else {
                  navigation(APP_ROUTE.DASHBOARDS.NONPROFIT);
                }
              }}
            >
              {(
                props?.role.charAt(0).toLocaleUpperCase() + props?.role.slice(1)
              )
                .replace('Super', '')
                .replace('Administrator', 'Admin') + ' Home'}
            </a>
          </li>
          {props.role === Strings.Roles.ADMIN ||
          props.role === Strings.Roles.SUPERADMIN ? (
            <></>
          ) : (
            <li
              className={`nav-item ${
                name === APP_ROUTE.PROFILES.USER ? 'active' : ''
              }`}
            >
              <a
                className="nav-link"
                onClick={() => {
                  navigation(APP_ROUTE.PROFILES.USER);
                }}
              >
                <i className="fas fa-user"></i>&nbsp;{Strings.header.profile}
              </a>
            </li>
          )}
          <li
            className={`nav-item ${
              name === APP_ROUTE.CHANGEPASSWORD ? 'active' : ''
            }`}
          >
            <a
              className="nav-link"
              onClick={() => {
                navigation(APP_ROUTE.CHANGEPASSWORD);
              }}
            >
              <i className="fas fa-key"></i>&nbsp;
              {Strings.header.changePassword}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={() => {
                logout(APP_ROUTE.LOGIN);
              }}
            >
              <i className="fas fa-lock"></i>&nbsp;{Strings.header.logout}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export { Header };
