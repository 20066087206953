import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import OTP from './otp';
import FormProvider from './FormProvider';

type FormValuesProps = {
    code1: string;
    code2: string;
    code3: string;
    code4: string;
    code5: string;
    code6: string;
};

type Props = {
    onPressSubmit: (code: string) => void;
    loading: boolean;
};

const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Code Required'),
    code2: Yup.string().required('Code Required'),
    code3: Yup.string().required('Code Required'),
    code4: Yup.string().required('Code Required'),
    code5: Yup.string().required('Code Required'),
    code6: Yup.string().required('Code Required'),
});

export function VerifyCodeForm(props: Props) {
    const { onPressSubmit, loading } = props;

    const defaultValues = {
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: '',
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(VerifyCodeSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting, errors },
    } = methods;

    const onSubmit = async (data: FormValuesProps) => {
        onPressSubmit(Object.values(data).join(''));
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                <OTP keyName="code" inputs={['code1', 'code2', 'code3', 'code4', 'code5', 'code6']} />

                {(!!errors.code1 ||
                    !!errors.code2 ||
                    !!errors.code3 ||
                    !!errors.code4 ||
                    !!errors.code5 ||
                    !!errors.code6) && (
                        <FormHelperText error sx={{ px: 2 }}>
                            Code Required
                        </FormHelperText>
                    )}

                <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    loading={loading || isSubmitting}
                    sx={{
                        backgroundColor: '#F9213B',
                        fontSize: '1.5em',
                    }}
                >
                    Submit
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
