import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <Box sx={{
                borderTop: '1px solid white',
                position: 'absolute',
                bottom: '0px',
                width: '100%',
                color: 'white',
                textAlign: 'center',
            }}>
                <span>Copyright 2023 Freedom Place.</span>
                <div className="w-100 d-block d-md-none"></div>
                <span>All Rights Reserved.</span>
                <div className="w-100 d-block d-md-none"></div>
                
                <Link to="https://www.freedomplace.com/about/terms" target='_blank' style={{
                    color: 'white',
                    paddingLeft: '10px'
                }}>Terms & Conditions</Link>

                <Link to="https://www.freedomplace.com/about/privacy" target='_blank' style={{
                    color: 'white',
                    paddingLeft: '10px'
                }}>Privacy Policy</Link>
            </Box>
        </>
    );
}
export { Footer }


const Footer2 = () => {
    return (
        <>
            {/* Desktop */}

            <div className="d-none d-md-block w-100">
                <Box sx={{
                    borderTop: '1px solid white',
                    bottom: '0px',
                    width: '100%',
                    color: 'white',
                    textAlign: 'right',
                    marginTop: '100px',
                    paddingTop: '30px',
                }}>

                    <div className="row">

                        <div className="d-none d-md-block">
                            <img alt="logo" src="/assets/img/logo.png" style={{
                                height: '40px',
                                display: 'block',
                                marginLeft: '20px',
                            }} />
                        </div>

                        <div className="col pt-2 d-none d-md-block">
                            Copyright 2023 Freedom Place. All Rights Reserved.

                            <Link to="https://www.freedomplace.com/about/terms" target='_blank' style={{
                                color: 'white',
                                paddingLeft: '10px'
                            }}>Terms & Conditions</Link>

                            <Link to="https://www.freedomplace.com/about/privacy" target='_blank' style={{
                                color: 'white',
                                paddingLeft: '10px'
                            }}>Privacy Policy</Link>
                        </div>

                    </div>

                </Box>
            </div>

            {/* End Desktop */}

            {/* Mobile */}

            <div className="d-block d-md-none w-100">
                <Box sx={{
                    borderTop: '1px solid white',
                    width: '100%',
                    color: 'white',
                    textAlign: 'right',
                    paddingTop: '30px',
                }}>

                    <div className="row">

                        <div className="d-block d-md-none d-flex w-100 justify-content-center">
                            <img alt="logo" src="/assets/img/logo.png" style={{
                                height: '40px',
                                display: 'block',
                                marginLeft: '20px',
                            }} />
                        </div>

                        <div className="col pt-2 d-block d-md-none text-center">

                            <div>
                                Copyright 2023 Freedom Place. All Rights Reserved.
                            </div>


                            <Link to="https://www.freedomplace.com/about/terms" target='_blank' style={{
                                color: 'white',
                                paddingLeft: '10px',
                                display: 'block',
                            }}>Terms & Conditions</Link>

                            <Link to="https://www.freedomplace.com/about/privacy" target='_blank' style={{
                                color: 'white',
                                paddingLeft: '10px'
                            }}>Privacy Policy</Link>
                        </div>

                    </div>

                </Box>
            </div>

            {/* End Mobile */}
        </>
    );
}
export { Footer2 }
