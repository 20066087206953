/* eslint-disable no-void */
import React, { useState } from 'react';
import { Strings } from '@app/constants';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '@app/modules/dashboard/slice';
import { Header } from '@app/components/header';
// eslint-disable-next-line no-void

const ChangePasswordScreen: React.FC = () => {
  const [currentPassword, setInputState] = useState({ value: '', error: '' });
  const [isLoading, setisLoading] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');
  const [error, setError] = useState('');
  const user = useSelector((state: any) => state.user);
  const disptach = useDispatch();
  const [newPassword, setInputStateNewPassword] = useState({
    value: '',
    error: '',
  });
  const [confirmPassword, setInputStateConfirmPassword] = useState({
    value: '',
    error: '',
  });

  function handleChange(event: any, name: string): void {
    if (name === 'Current') {
      setInputState((prevState) => ({
        ...prevState,
        value: event.target.value,
      }));
    }
    if (name === 'New') {
      setInputStateNewPassword((prevState) => ({
        ...prevState,
        value: event.target.value,
      }));
    }
    if (name === 'Confirm') {
      setInputStateConfirmPassword((prevState) => ({
        ...prevState,
        value: event.target.value,
      }));
    }
    console.log(currentPassword, newPassword, confirmPassword);
  }

  function onChangePassword(): void {
    let isError = false;
    setError('');
    setInputState((prevState) => ({
      ...prevState,
      error: '',
    }));
    setInputStateNewPassword((prevState) => ({
      ...prevState,
      error: '',
    }));
    setInputStateConfirmPassword((prevState) => ({
      ...prevState,
      error: '',
    }));
    if (currentPassword.value === '') {
      setInputState((prevState) => ({
        ...prevState,
        error: 'The current password field is required.',
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isError = true;
    }
    if (newPassword.value === '') {
      setInputStateNewPassword((prevState) => ({
        ...prevState,
        error: 'The new password is required.',
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isError = true;
    }
    if (confirmPassword.value === '') {
      setInputStateConfirmPassword((prevState) => ({
        ...prevState,
        error: 'Confirm new password is required.',
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isError = true;
    }
    if (newPassword.value !== confirmPassword.value) {
      setInputStateConfirmPassword((prevState) => ({
        ...prevState,
        error: 'The new password and confirmation password do not match.',
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isError = true;
    }

    if (!isError) {
      disptach(
        changePassword({
          currentPassword: currentPassword.value,
          newPassword: newPassword.value,
          setisLoading,
          setError,
          setInputStateNewPassword,
          setInputStateConfirmPassword,
          setInputState,
          setUpdateMessage,
        }),
      );
    }
  }

  return (
    <div>
      <Header role={user.role}>
        <></>
      </Header>
      <div className="jumbotron padding10  dashboard-padding-top">
        <div
          className="container text-center padding10"
          style={{ display: 'block' }}
        >
          <form method="post" action="./ConsumerDashboard" id="form1">
            <div className="row">
              <div className="col-12 change-pass-col">
                <h4>{Strings.changePassword.changePasswordTitile}</h4>

                <div
                  className="list-group list-group-form"
                  style={{ color: 'black' }}
                >
                  <div className="list-group-item">
                    <span id="MainContent_ctl00" className="text-danger">
                      <label style={{ color: '#dc3545' }}>{error}</label>
                    </span>
                    <div className="form-group row mb-0">
                      <label className="col-form-label col-sm-3">
                        {Strings.changePassword.currentPassword}
                      </label>
                      <div className="col-sm-9">
                        <input
                          name="ctl00$MainContent$CurrentPassword"
                          type="password"
                          value={currentPassword.value}
                          onChange={(event) => {
                            handleChange(event, 'Current');
                            return void 0;
                          }}
                          id="MainContent_CurrentPassword"
                          className="form-control"
                        />
                        <br />
                        <span id="MainContent_ctl00" className="text-danger">
                          <label style={{ color: '#dc3545' }}>
                            {currentPassword.error}
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="list-group-item">
                    <div className="form-group row mb-0">
                      <label className="col-form-label col-sm-3">
                        {Strings.changePassword.newPassword}
                      </label>
                      <div className="col-sm-9">
                        <input
                          name="ctl00$MainContent$NewPassword"
                          type="password"
                          value={newPassword.value}
                          onChange={(event) => {
                            handleChange(event, 'New');
                            return void 0;
                          }}
                          id="MainContent_NewPassword"
                          className="form-control"
                        />
                        <br />
                        <span id="MainContent_ctl01" className="text-danger">
                          <label style={{ color: '#dc3545' }}>
                            {newPassword.error}
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="form-group row mb-0">
                      <label className="col-form-label col-sm-3">
                        {Strings.changePassword.confirmPassword}
                      </label>
                      <div className="col-sm-9">
                        <input
                          name="ctl00$MainContent$ConfirmNewPassword"
                          value={confirmPassword.value}
                          onChange={(event) => {
                            handleChange(event, 'Confirm');
                            return void 0;
                          }}
                          type="password"
                          id="MainContent_ConfirmNewPassword"
                          className="form-control"
                        />
                        <br />
                        <span id="MainContent_ctl02" className="text-danger">
                          <label style={{ color: '#dc3545' }}>
                            {confirmPassword.error}
                          </label>
                        </span>
                        {/* <span id="MainContent_ctl03" className="text-danger">{confirmPassword.error}</span> */}
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="text-center">
                      {!isLoading ? (
                        <input
                          name="ctl00$MainContent$ctl04"
                          value="Change Password"
                          onClick={onChangePassword}
                          className="btn btn-primary"
                          style={{ width: '14.4%' }}
                          type="button"
                        />
                      ) : (
                        <input
                          type="button"
                          name="ctl00$MainContent$ctl04"
                          value="Changing..."
                          className="btn btn-primary"
                          style={{ width: '14.4%' }}
                        />
                      )}
                      <br />
                    </div>
                    {updateMessage !== '' ? (
                      <span
                        id="MainContent_ErrorMessage"
                        style={{ color: 'green' }}
                      >
                        <br /> {updateMessage}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
          <br />
          &copy; {Strings.footer.rights}
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordScreen;
