/**
 * Auth Slice
 * @format
 */

import { createAction } from '@reduxjs/toolkit';

import {
  type LoginRequest,
  type SignupRequest,
  type ForgotPasswordRequest,
  type VerifyCodeRequest,
  type SetPasswordRequest,
  CheckEmailRequest,
} from './types';

// Actions )-------------------------------------
export const login = createAction<LoginRequest>('AUTH/LOGIN');

export const signup = createAction<SignupRequest>('AUTH/SIGNUP');

export const requestResetPassword = createAction<ForgotPasswordRequest>(
  'AUTH/FORGET_PASSWORD',
);

export const setPassword =
  createAction<SetPasswordRequest>('AUTH/SET_PASSWORD');

export const getAnnoucments = createAction<any>('AUTH/GET_ANNOUCEMENTS');

export const verifyCode = createAction<VerifyCodeRequest>('AUTH/VERIFY_CODE');

export const deleteAll = createAction<any>('AUTH/DELETE_ALL');

export const checkEmail = createAction<CheckEmailRequest>('AUTH/CHECK_EMAIL');