/**
 * Default theme
 */

import { createTheme } from '@mui/material';

import * as Colors from './colors';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: { main: Colors.secondary },
  },
});

export { defaultTheme };
