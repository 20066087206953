import { Helmet } from 'react-helmet-async';
import { Container, Grid, Typography } from '@mui/material';
import { Video } from '@app/components/common/video';
import { SmallContainer } from '@app/components/common/small-container';
import { Logo } from '@app/components/common/logo';

const SessionErrorScreen = () => {
  return (
    <>
      <Helmet>
        <title>Error - Freedom Place</title>
        <style>
          {
            'body { background-color: black; }'
          }
        </style>
      </Helmet>

      <Container sx={{
        paddingBottom: '40px',
        maxWidth: '1400px !important',
        minHeight: '100vh !important',
        position: 'relative',
        overflow: 'hidden',
        paddingLeft: '0px !important',
      }}>

        <Video />

        <Grid container justifyContent="center" sx={{
          width: '100%',
        }}>

          <SmallContainer>
            <Logo />

            <Grid container spacing={2} justifyContent="center" sx={{
              padding: '30px',
            }}>
              <Typography variant="h4" marginTop="30px" marginBottom="20px" className='text-center'>UNEXPECTED SESSION ERROR</Typography>

              <Typography variant="body1" sx={{
                color: 'gray', textAlign: 'center',
              }}>
                Oops, looks like the application has experienced an unexpected error.
              </Typography>

              <Typography variant="body1" sx={{
                color: 'gray', textAlign: 'center',
              }}>
                Please close your browser window and re-launch the Freedom Place Portal.
              </Typography>

              <Typography variant="body1" sx={{
                color: 'gray', textAlign: 'center',
              }}>
                If this problem persists, please contact suport for assistance.
              </Typography>
            </Grid>
          </SmallContainer>
        </Grid>
      </Container>
    </>
  );
};

export default SessionErrorScreen;
