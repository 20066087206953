import React, { useEffect } from 'react';
import { APP_ROUTE } from '@app/constants';
import './dashboard.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProfile, getUserWaves, updateInitialState } from '../slice';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Stack } from '@mui/material';
import { BigContainer } from '@app/components/common/big-container';
import { FaTelegramPlane, FaRedditAlien, FaTwitter, FaLinkedin } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { BiCopy } from "react-icons/bi";
import { MdSms, MdEmail } from "react-icons/md";
import Truth from '@app/components/common/truth';
import { ImFacebook2 } from "react-icons/im";
import Gab from '@app/components/common/gab';
import { enqueueSnackbar } from 'notistack';
import { DashTop, DashTopMob } from './dash-top';
import { WaveTab } from './wave-tab';
import { Footer2 } from '@app/components/common/footer';
import { TopBar } from './top-bar';

const FConsumerDashboardScreen: React.FC = () => {

    const user = useSelector((state: any) => state.user);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });


    async function copyToClipboard(): Promise<void> {
        try {
            await navigator.clipboard.writeText(getShareUrl());
            enqueueSnackbar('Link copied!', {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                variant: "success",
            });
        } catch (error) {
            enqueueSnackbar('Failed to copy to clipboard', {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                variant: "error",
            });
        }
    }

    let isCalled = false;

    useEffect(() => {
        if (user.token === undefined || user.token.length <= 0) {
            navigation(APP_ROUTE.LOGIN);
        }
        console.log('getProfileNonDashboard', user.isFetching);
        console.log('user', user);
        // if (Object.keys(user.waveMetricsModels).length <= 0) {
        //   dispatch(updateInitialState({}));
        // }
        if (!isCalled) {
            dispatch(updateInitialState({}));
            dispatch(getProfile({ navigation }));
            dispatch(getUserWaves({}));
            isCalled = true;
        }
    }, []);

    function sms(): string {
        return `sms:?&body=Pass%20Your%20Unique-To-You%20Trackable%20Link ${getShareUrl()}`;
    }

    function emailLink(): string {
        return `mailto:?subject=Freedom%20Place%20Shareable%20Link&body=I signed up for free to become a Freedom Place Partner and I urge you to join me along with many leaders of the freedom movement.%0d%0aThis is how we take back our country and restore the values of God, Family and Country.%0d%0aThey’ve changed the rules of buying and selling.  This is very unique.   Live webinars every night. We’re currently in stealth mode. %0d%0aClick the link and join fast, get your link and pass it on.%0d%0a${getShareUrl()}`;
    }

    function telegram(): string {
        return `https://telegram.me/share/url?url=${getShareUrl()}&text=I'm on Freedom Place. It has changed the rules of buying and selling. We can all sign up for free, pass a link and Freedom Place does the rest.  It reverses the flow of money out of Big Media/Big Tech into the accounts of small businesses and people, just like me. SIGN UP HERE`;
    }

    function truth(): string {
        return `https://truthsocial.com/`;
    }

    function reddit(): string {
        return `https://reddit.com/submit?url=${getShareUrl()}`;
    }

    function gab(): string {
        return `https://gab.com/compose?url=${getShareUrl()}`;
    }

    function twitter(): string {
        const baseTwitterUrl = 'https://twitter.com/intent/tweet';
        const siteUrl = getShareUrl();
        const text = "I'm on Freedom Place. It has changed the rules of buying and selling. We can all sign up for free, pass a link and Freedom Place does the rest. It reverses the flow of money out of Big Media/Big Tech into the accounts of small businesses and people, just like me. SIGN UP HERE";

        const encodedSiteUrl = encodeURIComponent(siteUrl);
        const encodedText = encodeURIComponent(text);
        const shareLink = `${baseTwitterUrl}?url=${encodedSiteUrl}&text=${encodedText}`;
        return shareLink;
    }

    function facebook(): string {
        const baseFacebookUrl = 'https://www.facebook.com/sharer/sharer.php';
        const siteUrl = getShareUrl();
        const title =
            "I'm on Freedom Place. It has changed the rules of buying and selling. We can all sign up for free, pass a link and Freedom Place does the rest. It reverses the flow of money out of Big Media/Big Tech into the accounts of small businesses and people, just like me. SIGN UP HERE";

        const encodedSiteUrl = encodeURIComponent(siteUrl);
        const encodedTitle = encodeURIComponent(title);

        return `${baseFacebookUrl}?u=${encodedSiteUrl}&title=${encodedTitle}`;
    }

    function whatsapp(): string {
        return `https://api.whatsapp.com/send?text=Im on Freedom Place. It has changed the rules of buying and selling. We can all sign up for free, pass a link and Freedom Place does the rest.  It reverses the flow of money out of Big Media/Big Tech into the accounts of small businesses and people, just like me. SIGN UP HERE:%20${getShareUrl()}`;
    }

    function linkedin(): string {
        const baseLinkedInUrl = 'https://www.linkedin.com/sharing/share-offsite';
        const siteUrl = getShareUrl();
        const title = "I'm on Freedom Place. It has changed the rules of buying and selling. We can all sign up for free, pass a link and Freedom Place does the rest. It reverses the flow of money out of Big Media/Big Tech into the accounts of small businesses and people, just like me. SIGN UP HERE";
        const encodedTitle = encodeURIComponent(title);
        const encodedSiteUrl = encodeURIComponent(siteUrl);
        const shareLink = `${baseLinkedInUrl}?url=${encodedSiteUrl}&title=${encodedTitle}`;

        return shareLink;
    }

    function getHomeUrl(): string {
        const parsedUrl = new URL(window.location.href);
        let u = parsedUrl.origin;
        if (!u.endsWith('/')) u += '/';
        return u;
    }

    function getShareUrl(): string {
        return `${getHomeUrl()}${user.role}/signup?id=${user.linkId}`;
    }

    return (
        <>
            <Helmet>
                <title>Dashboard - Freedom Place</title>
                <style>
                    {
                        'body { background-color: black; }'
                    }
                </style>
            </Helmet>

            <TopBar/>

            <Container sx={{
                paddingBottom: '40px',
                maxWidth: '1437px !important',
                minHeight: '100vh !important',
                position: 'relative',
                overflow: 'hidden',
                paddingLeft: '0px !important',
                paddingRight: '0px !important'
            }}>

                <img src='/assets/img/cover.jpg' style={{
                    width: '100%',
                    position: 'absolute',
                }} />

                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        backgroundImage: 'linear-gradient(to bottom, rgba(22,28,36,0.9), rgba(22,28,36,1))',
                    }}
                >
                </div>

                <Grid container justifyContent="center" sx={{
                    width: '100%',
                }}>

                    <BigContainer sx={{
                        marginTop: '20px !important',
                    }}>

                        <DashTop user={user} />
                        <DashTopMob user={user} />

                        <Stack className='p-1 p-md-3' sx={{
                            backgroundColor: '#212B36',
                            width: '100%',
                            borderRadius: '20px',
                            marginTop: '20px',
                        }}>
                            <h5 className='mb-3'>
                                Share your unique FreedomLink™
                            </h5>

                            {/* Copy Link */}

                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'wrap',
                                backgroundColor: '#1C242D',
                                borderRadius: '10px',
                            }}>

                                <div className='col' style={{
                                    padding: '13px 20px',
                                }}>
                                    {getShareUrl()}
                                </div>

                                <div className='copy'
                                    style={{
                                        backgroundColor: '#161C24',
                                        padding: '13px 20px',
                                        borderTopRightRadius: '10px',
                                        borderBottomRightRadius: '10px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        copyToClipboard();
                                    }}
                                >
                                    <span style={{ marginRight: '10px', }}>Copy to Clipboard</span>
                                    <BiCopy size={24} />
                                </div>

                            </div>

                            {/* End Copy Link */}

                            {/* Text and Email */}

                            <Stack sx={{
                                backgroundColor: '#F9213B',
                                width: '100%',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                marginTop: '20px',
                            }}>
                                <div className='row'
                                    style={{
                                        width: '100%',
                                        margin: '0px !important',
                                    }}
                                >
                                    <div className='col' style={{
                                        fontSize: '20px',
                                    }}>
                                        Share via Text or Email
                                    </div>

                                    <div>
                                        <a className='f-anchor mr-3' href={sms()}>
                                            <MdSms size={30} />
                                        </a>

                                        <a className='f-anchor' href={emailLink()}>
                                            <MdEmail size={30} />
                                        </a>

                                    </div>
                                </div>
                            </Stack>

                            {/* End Text and Email */}

                            {/* Social */}

                            <Stack sx={{
                                backgroundColor: '#0065FF',
                                width: '100%',
                                borderRadius: '10px',
                                padding: '10px 20px',
                                marginTop: '20px',
                            }}>

                                <div className='row'
                                    style={{
                                        width: '100%',
                                        margin: '0px !important',
                                    }}
                                >
                                    <div className='col' style={{
                                        fontSize: '20px',
                                    }}>
                                        Share via Social
                                    </div>

                                    <div>
                                        <a className='f-anchor mr-3' href={telegram()} target='_blank'>
                                            <FaTelegramPlane size={30} />
                                        </a>

                                        <a className='f-anchor mr-3' href={truth()} target='_blank'>
                                            <Truth size={30} color='#C8C8C8' colorHover='white' />
                                        </a>

                                        <a className='f-anchor mr-3' href={reddit()} target='_blank'>
                                            <FaRedditAlien size={30} />
                                        </a>

                                        <a className='f-anchor mr-3' href={gab()} target='_blank'>
                                            <Gab size={30} color='#C8C8C8' colorHover='white' />
                                        </a>

                                        <a className='f-anchor mr-3' href={twitter()} target='_blank'>
                                            <FaTwitter size={30} />
                                        </a>

                                        <a className='f-anchor mr-3' href={facebook()} target='_blank'>
                                            <ImFacebook2 size={30} />
                                        </a>

                                        <a className='f-anchor mr-3' href={whatsapp()} target='_blank'>
                                            <RiWhatsappFill size={30} />
                                        </a>

                                        <a className='f-anchor' href={linkedin()} target='_blank'>
                                            <FaLinkedin size={30} />
                                        </a>
                                    </div>
                                </div>


                            </Stack>

                            {/* End Social */}



                        </Stack>

                        <WaveTab user={user} />

                    </BigContainer>

                    {/* Footer */}

                    <Grid container spacing={2} className='p-1 p-md-3'
                        justifyContent="center" sx={{
                            position: 'relative',
                            marginTop: '40px',
                            marginLeft: '10px',
                            maxWidth: '1080px',
                            color: 'white',
                        }}>
                        <Footer2 />
                    </Grid>

                    {/* End Footer */}

                </Grid>

            </Container>
        </>
    );
};

export default FConsumerDashboardScreen;
