/* eslint-disable @typescript-eslint/restrict-plus-operands */
/**
 * Auth Sagas
 * @format
 */

import { takeLatest, all, call, put } from 'redux-saga/effects';

import { api, request, endpoints } from '@app/api';
// import { showAlert, showMessage } from '@app/global';
// import { NavigationService } from '@app/utils';
// import { strings } from '@app/strings';
// import { prepareUserSessionSaga } from '@app/modules/app-bootstrap';
// import { createLoader, setAuthToken } from '@app/modules/common';
import {
  login,
  signup,
  requestResetPassword,
  verifyCode,
  setPassword,
  deleteAll,
  getAnnoucments,
  checkEmail,
} from './slice';
import { APP_ROUTE, Strings } from '@app/constants';
import { enqueueSnackbar } from 'notistack';

function* tryLoginSaga({ payload }: ReturnType<typeof login>): any {
  try {
    // yield put(loader.present());
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
    const {
      email,
      password,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      grand_type,
      navigate,
      setisLoading,
    } = payload;
    setisLoading(true);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {
      secretKey,
      username,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      access_token,
      userId,
      ReferrerLinkId,
      LinkId,
      role,
    } = yield call(request.post, endpoints.login, {
      grant_Type: grand_type,
      userName: 'string',
      email,
      password,
      refresh_Token: 'string',
      userId: 'string',
    });
    setisLoading(false);
    if (access_token !== undefined) {
      yield call(saveTokenSaga, access_token);
      yield put({
        type: Strings.Actions.UPDATE_USER,
        payload: {
          userId,
          token: access_token,
          ReferrerLinkId,
          linkId: LinkId,
          email,
          role,
          username,
        },
      });
      if (role.toLowerCase() === 'consumer') {
        navigate(APP_ROUTE.DASHBOARDS.USER);
      } else if (role.toLowerCase() === 'business') {
        navigate(APP_ROUTE.DASHBOARDS.BUSINESS);
      } else if (
        role.toLowerCase() === 'admin' ||
        role.toLowerCase() === 'super administrator'
      ) {
        navigate(APP_ROUTE.DASHBOARDS.ADMIN);
      } else {
        navigate(APP_ROUTE.DASHBOARDS.NONPROFIT);
      }
    } else {
      yield put({
        type: Strings.Actions.UPDATE_USER,
        payload: {
          secretKey,
          username,
        },
      });
      navigate(APP_ROUTE.VERIFICATION);
    }
    // yield call(saveTokenSaga, access_token);
  } catch (error: any) {
    console.log(error);
    payload.setisLoading(false);
    payload.setPasswordError(error.message);
    // showAlert(strings.errors.login_failed, error.message);
  } finally {
    // yield put(loader.dismiss());
  }
}

function* verifyCodeSaga({ payload }: ReturnType<typeof verifyCode>): any {
  //   const loader = createLoader();
  try {
    // yield put(loader.present());
    const { username, code, secretKey, navigate, setisLoading } = payload;
    setisLoading(true);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {
      userId,
      ReferrerLinkId,
      LinkId,
      role,
      email,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      access_token,
      isOtpValid,
    } = yield call(request.post, endpoints.verify_otp, {
      username,
      oneTimeCode: code,
      secretKey,
    });
    if (isOtpValid === false) {
      setisLoading(false);
      payload.setOtpError('Invalid Code!');
      return;
    }
    yield call(saveTokenSaga, access_token);
    yield put({
      type: Strings.Actions.UPDATE_USER,
      payload: {
        userid: userId,
        token: access_token,
        ReferrerLinkId,
        linkId: LinkId,
        email,
        role,
        username,
      },
    });

    const {
      firstName,
      lastName,
      cellPhone,
      country,
      city,
      regionOrState,
      businessName,
      waveMetricsModels,
    } = yield call(request.get, endpoints.initial_profile);

    yield put({
      type: Strings.Actions.UPDATE_USER,
      payload: {
        firstName,
        lastName,
        cellPhone,
        country,
        city,
        regionOrState,
        businessName,
        isFetching: false,
        isConsumerApiLoading: false,
        isWaveApiLoading: false,
        waveMetricsModels:
          waveMetricsModels !== undefined && waveMetricsModels !== null
            ? waveMetricsModels[0]
            : {
              signUpsPast30: 0,
              signUpsPast60: 0,
              signUpsPast90: 0,
              signUpsToday: 0,
              wave1_Past30: 0,
              wave1_Past60: 0,
              wave1_Past90: 0,
              wave1_Today: 0,
              wave2_Past30: 0,
              wave2_Past60: 0,
              wave2_Past90: 0,
              wave2_Today: 0,
              wave3_Past30: 0,
              wave3_Past60: 0,
              wave3_Past90: 0,
              wave3_Today: 0,
              wave4_Past30: 0,
              wave4_Past60: 0,
              wave4_Past90: 0,
              wave4_Today: 0,
            },
      },
    });

    setisLoading(false);
    if (role.toLowerCase() === 'consumer') {
      navigate(APP_ROUTE.DASHBOARDS.USER);
    } else if (role.toLowerCase() === 'business') {
      navigate(APP_ROUTE.DASHBOARDS.BUSINESS);
    } else if (
      role.toLowerCase() === 'admin' ||
      role.toLowerCase() === 'super administrator'
    ) {
      navigate(APP_ROUTE.DASHBOARDS.ADMIN);
    } else {
      navigate(APP_ROUTE.DASHBOARDS.NONPROFIT);
    }
  } catch (error: any) {
    payload.setisLoading(false);
    if (error.message !== '') {
      payload.setOtpError(error.message);
    } else {
      payload.setOtpError('Something Went Wrong!');
    }
    // showAlert(strings.errors.login_failed, error.message);
  } finally {
    // yield put(loader.dismiss());
  }
}

function* signupSaga({ payload }: ReturnType<typeof signup>): any {
  //   const loader = createLoader();
  try {
    // yield put(loader.present());
    const {
      firstName,
      lastName,
      email,
      cellPhone,
      password,
      consumerLinkID,
      refererName,
      ageAndTerms,
      businessName,
      role,
      city,
      country,
      regionOrState,
      navigator,
      setisLoading,
    } = payload;
    setisLoading(true);
    const data = yield call(request.post, endpoints.register, {
      firstName,
      lastName,
      email,
      cellPhone,
      password,
      consumerLinkID,
      refererName,
      ageAndTerms,
      role,
      city,
      country,
      businessName,
      regionOrState,
    });
    console.log(data);
    setisLoading(false);
    yield put({
      type: Strings.Actions.DELETE_ALL,
      payload: {},
    });
    navigator(APP_ROUTE.POSTSIGNUP, { state: refererName });
  } catch (error: any) {
    payload.setisLoading(false);
    payload.setErrorMessage(error.message);

    // showAlert('', error.message);
  } finally {
    // yield put(loader.dismiss());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* requestResetPasswordSaga({
  payload,
}: ReturnType<typeof requestResetPassword>) {
  //   const loader = createLoader();
  try {
    // yield put(loader.present());
    const { email, setIsRequestSent, setisLoading } = payload;
    setisLoading(true);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    yield call(request.post, endpoints.forget_password, { email });
    setIsRequestSent(true);
    setisLoading(false);
    // yield call(showAlert, '', strings.success.password_reset_link_sent);
    // yield call(NavigationService.goBack);
  } catch (error: any) {
    console.log('requestResetPasswordSaga error', error);
    payload.setisLoading(false);
    payload.setEmailError(error.message);
    // showAlert('', error.message);
  } finally {
    // yield put(loader.dismiss());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* setPasswordSaga({ payload }: ReturnType<typeof setPassword>) {
  //   const loader = createLoader();
  try {
    // yield put(loader.present());
    const { password, confirmPassword, resetUserId, navigate, setisLoading } =
      payload;
    setisLoading(true);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    yield call(request.post, endpoints.set_password, {
      password,
      confirmPassword,
      resetUserId,
    });
    setisLoading(false);
    navigate(APP_ROUTE.LOGIN);
    // yield call(showAlert, '', strings.success.password_reset_link_sent);
    // yield call(NavigationService.goBack);
  } catch (error: any) {
    payload.setisLoading(false);
    payload.setEmailError(error.message);
    // showAlert('', error.message);
  } finally {
    // yield put(loader.dismiss());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* getAnnouncementSaga({ payload }: ReturnType<typeof getAnnoucments>) {
  //   const loader = createLoader();
  try {
    // yield put(loader.present());
    const { linkid, role, navigate, setAnnoucement, setUsername } = payload;
    // setisLoading(true);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unused-vars
    const { referrerName, announcement } = yield call(
      request.get,
      endpoints.get_annoucement + linkid + '/' + role,
    );

    console.log('referrerName', referrerName);
    if (referrerName == 'Unknown') {
      navigate(APP_ROUTE.SIGNUPLINKREQUIRED);
    } else {
      setAnnoucement(announcement);
      setUsername(referrerName);
    }
    // yield call(showAlert, '', strings.success.password_reset_link_sent);
    // yield call(NavigationService.goBack);
  } catch (error: any) {
    payload.navigate(APP_ROUTE.SIGNUPLINKREQUIRED);
    // showAlert('', error.message);
  } finally {
    // yield put(loader.dismiss());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* saveTokenSaga(token: string): Generator<never, void, unknown> {
  //   yield put(setAuthToken(token));
  api.setToken(token);
  //   yield call(prepareUserSessionSaga);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* deleteAllSaga(): Generator<unknown> {
  yield put({
    type: Strings.Actions.DELETE_ALL,
    payload: {},
  });
}

function* checkEmailSaga({ payload }: ReturnType<typeof checkEmail>) {
  const { email, setEmailError } = payload;

  try {

    let url = `${endpoints.check_email}?email=${email}`;
    const { result } = yield call(
      request.get,
      url
    );

    console.log('email result', result);
    if (result && result.length > 0) {
      setEmailError(result);
    }

  } catch (error: any) {
    setEmailError(error.message);
  } finally {
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* authSagas() {
  yield all([
    takeLatest(login, tryLoginSaga),
    takeLatest(deleteAll, deleteAllSaga),
    takeLatest(verifyCode, verifyCodeSaga),
    takeLatest(signup, signupSaga),
    takeLatest(requestResetPassword, requestResetPasswordSaga),
    takeLatest(setPassword, setPasswordSaga),
    takeLatest(getAnnoucments, getAnnouncementSaga),
    takeLatest(checkEmail, checkEmailSaga),
  ]);
}

export { authSagas };
