import { useState } from "react";

export default function Truth(props: any) {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    return (
        <svg width={props.size} height={props.size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <path d="M23.3685 8.76684V2.80005H9.47821V24.827H16.568V8.76684H23.3685Z" fill={isHovered ? props.colorHover : props.color} />
            <path d="M0 2.80005H6.8062V8.76537H0V2.80005Z" fill={isHovered ? props.colorHover : props.color} />
            <path d="M28 18.9309H21.1832V24.8389H28V18.9309Z" fill={isHovered ? props.colorHover : props.color} />
        </svg>
    );
}

