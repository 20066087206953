import { Grid } from "@mui/material";

const SmallContainer = (props: any) => {
    return (
        <Grid container spacing={2} justifyContent="center" sx={{
            position: 'relative',
            backgroundColor: '#161C24',
            marginTop: '40px',
            marginBottom: '40px',
            marginLeft: '10px',
            marginRight: '10px',
            borderRadius: '20px',
            maxWidth: '520px',
            color: 'white',
            padding: '30px',
        }}>
            {props.children}
        </Grid>
    );
}
export { SmallContainer }

