import { enqueueSnackbar } from "notistack";

const FSnack = {
    showError: (s: string) => {
        enqueueSnackbar(s, {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            variant: "error",
        });
    },

    showSuccess: (s: string) => {
        enqueueSnackbar(s, {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            variant: "success",
        });
    }
}
export { FSnack }