/**
 * Routes
 */

const APP_ROUTE = Object.freeze({
  ROOT: '/',
  LOGIN: '/Account/Login',
  FORGET: '/Account/Forget',
  RESETPASSWORD: '/Account/ResetPassword',
  HOME: '/home',
  CHANGEPASSWORD: '/system/admin/ChangePassword',
  PROFILE: '/profile',
  VERIFICATION: '/verification',
  SESSIONOUT: '/sessionTimeOut',
  SESSIONERROR: '/SessionError',
  SIGNUP: '/SignUp',
  POSTSIGNUP: '/Account/PostSignUp',
  SIGNUPLINKREQUIRED: '/SignupLinkRequired',
  DASHBOARDS: {
    USER: '/system/admin/ConsumerDashboard',
    BUSINESS: '/system/admin/BusinessDashboard',
    NONPROFIT: '/system/admin/ChurchDashboard',
    ADMIN: '/system/admin/AdminDashboard',
  },
  PROFILES: {
    USER: '/system/admin/ConsumerProfile',
    BUSINESS: '/system/admin/BusinessProfile',
    NONPROFIT: '/system/admin/ChurchProfile',
  },
  SIGNUPS: {
    USER: '/consumer/SignUp',
    BUSINESS: '/business/SignUp',
    NONPROFIT: '/NotForProfit/SignUp',
  },
});

export { APP_ROUTE };
