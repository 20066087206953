import { Avatar, Grid, Stack } from "@mui/material";

const DashTop = (props: any) => {
    const user = props.user;

    return (
        <div className="d-none d-md-block w-100">
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '200px',
                    marginLeft: '0px !important',
                    marginTop: '-20px',
                }}
            >
                <div className='col'>
                    <Stack direction="row" sx={{
                        height: '100%',
                        alignItems: 'center',
                    }}>
                        <Avatar alt="avatar" src="/assets/img/no-avatar.png" sx={{ width: 70, height: 70 }} />
                        <Stack sx={{
                            marginLeft: '20px',
                        }}>
                            {
                                (user.role == 'Business' || user.role == 'NotForProfit') && (
                                    <h5>
                                        Welcome, {user.businessName}
                                    </h5>
                                )
                            }

                            {
                                (user.role == 'Consumer') && (
                                    <h5>
                                        Welcome, {user.firstName} {user.lastName}
                                    </h5>
                                )
                            }

                            <div>
                                <span className="mr-3 pl-2 pr-2" style={{
                                    backgroundColor: 'white',
                                    borderRadius: '6px',
                                    color: 'black',
                                }}>
                                    {user.role}
                                </span>

                                #{user.linkId}
                            </div>
                        </Stack>
                    </Stack>

                </div>

                <div className='w-100 d-block d-md-none'></div>

                <div className='col' style={{
                    paddingRight: '0px',
                }}>
                    <iframe className="iframe" width="560" height="315"
                        src="https://www.youtube.com/embed/nrU2_iIHZ3c"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    ></iframe>
                </div>

            </div>
        </div>
    );
}
export { DashTop }

const DashTopMob = (props: any) => {
    const user = props.user;

    return (
        <div className="d-block d-md-none" style={{
            marginLeft: '-40px',
        }}>
            <Grid container justifyContent="center">
                <Stack direction="row" sx={{
                    alignItems: 'center',
                }}>
                    <Avatar alt="avatar" src="/assets/img/no-avatar.png" sx={{ width: 70, height: 70 }} />
                    <Stack sx={{
                        marginLeft: '20px',
                    }}>
                        {
                            (user.role == 'Business' || user.role == 'NotForProfit') && (
                                <h5>
                                    Welcome, {user.businessName}
                                </h5>
                            )
                        }

                        {
                            (user.role == 'Consumer') && (
                                <h5>
                                    Welcome, {user.firstName} {user.lastName}
                                </h5>
                            )
                        }

                        <div>
                            <span className="mr-3 pl-2 pr-2" style={{
                                backgroundColor: 'white',
                                borderRadius: '6px',
                                color: 'black',
                            }}>
                                {user.role}
                            </span>

                            #{user.linkId}
                        </div>
                    </Stack>
                </Stack>

                <div style={{
                    width: '100%',
                    minHeight: '200px',
                    marginTop: '40px',
                    paddingLeft: '10px',
                    paddingRight: '30px',
                }}>
                    <iframe className="iframe" width="560" height="315"
                        src="https://www.youtube.com/embed/nrU2_iIHZ3c"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    ></iframe>
                </div>
            </Grid>
        </div>
    );
}
export { DashTopMob }

