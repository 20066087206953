/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * Login screen
 */

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../auth/views/login.scss';
import { APP_ROUTE } from '@app/constants';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Typography } from '@mui/material';
import { Video } from '@app/components/common/video';
import { SmallContainer } from '@app/components/common/small-container';
import { Logo } from '@app/components/common/logo';
import { LoadingButton } from '@mui/lab';

const PostSignupScreen: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setisLoading] = useState(false);
  
  function goToLogin() {
    navigate(APP_ROUTE.LOGIN);
  }

  useEffect(() => {
    console.log('PostSignupScreen', state);
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign Up Successful - Freedom Place</title>
        <style>
          {
            'body { background-color: black; }'
          }
        </style>
      </Helmet>

      <Container sx={{
        paddingBottom: '40px',
        maxWidth: '1400px !important',
        minHeight: '100vh !important',
        position: 'relative',
        overflow: 'hidden',
        paddingLeft: '0px !important',
      }}>
        <Video />

        <Grid container justifyContent="center" sx={{
          width: '100%',
        }}>
          <SmallContainer>
            <Logo />

            <Grid container spacing={2} justifyContent="center" >
              <Typography variant="h4" marginTop="30px" marginBottom="20px">Sign Up Successful</Typography>
            </Grid>

            <div className='text-center'>
              1. When you click &quot;Next&quot; below, you will be
              forwarded to the Login Page
              <br />
              <br />
              2. Enter your Email and PW (you have an email already with
              that information if you forgot!)
              <br />
              <br />
              3. When you hit Login button on next page, you will be emailed
              a 6 digit verification code.
              <br />
              <br />
              4. For now, you will have to do that every time you login due
              to the level of security required for this type of enterprise
              in today&apos;s world.
              <br />
            </div>

            <LoadingButton className='mt-4' loading={isLoading} loadingPosition='end' variant="contained"
              onClick={goToLogin}
            >
              <span>Next</span>
            </LoadingButton>
          </SmallContainer>
        </Grid>
      </Container>
    </>
  );
};

export default PostSignupScreen;
