

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './login.scss';
import { APP_ROUTE, Strings } from '@app/constants';
import { useDispatch } from 'react-redux';
import { requestResetPassword } from '../slice';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Stack, Typography } from '@mui/material';
import { Video } from '@app/components/common/video';
import { SmallContainer } from '@app/components/common/small-container';
import { Logo } from '@app/components/common/logo';
import { LoadingButton } from '@mui/lab';
import { Footer } from '@app/components/common/footer';
import { TextBox } from '@app/components/common/text-box';
import { enqueueSnackbar } from 'notistack';
import { FSnack } from '@app/components/common/f-snack';

const FForget = () => {
    const [isRequestSent, setIsRequestSent] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    function goToLogin() {
        navigate(APP_ROUTE.LOGIN);
    }
    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const sent = (b: boolean) => {
        setIsRequestSent(b);
        FSnack.showSuccess('Reset password link sent successfully to your given email address.');
    }

    const emailErrorMethod = (m: string) => {
        setEmailError(m);
        FSnack.showError(m);
    }

    function checkValidation() {
        const emailRegex = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Email is required.');
            return;
        } else {
            setEmailError('');
        }
        // navigate('/verification');
        dispatch(
            requestResetPassword({
                email: email.toLowerCase(),
                setIsRequestSent: sent,
                setEmailError: emailErrorMethod,
                setisLoading,
            }),
        );
    }

    return (
        <>
            <Helmet>
                <title>Forgot Password - Freedom Place</title>
                <style>
                    {
                        'body { background-color: black; }'
                    }
                </style>
            </Helmet>

            <Container sx={{
                paddingBottom: '40px',
                maxWidth: '1400px !important',
                minHeight: '100vh !important',
                position: 'relative',
                overflow: 'hidden',
                paddingLeft: '0px !important',
            }}>
                <Video />

                <Grid container justifyContent="center" sx={{
                    width: '100%',
                }}>
                    <SmallContainer>
                        <Logo />

                        <Grid container spacing={2} justifyContent="center" sx={{
                            padding: '30px',
                        }}>
                            <h5 className="d-block d-md-none mb-4" style={{
                                fontSize: '22px',
                                marginTop: '20px',
                            }}>Forgot your password?
                            </h5>

                            <Typography variant="h4" marginTop="30px" marginBottom="20px" className='d-none d-md-block'>Forgot your password?</Typography>

                            <Typography variant="body1" sx={{
                                color: 'gray',
                                textAlign: 'center',
                            }}>
                                Please enter the email address associated with your account, and we'll email you a link
                                to reset your password.
                            </Typography>
                        </Grid>

                        <Stack className='w-100'>
                            <TextBox label="Email Address" value={email}
                                onChange={(event) => {
                                    handleEmailChange(event);
                                    return void 0;
                                }} />

                            {
                                emailError.length > 0 && (
                                    <div className='alert alert-danger'>{emailError}</div>
                                )
                            }
                        </Stack>

                        <div className='row mt-3 pr-3'>
                            <div className='col'></div>
                            <LoadingButton loading={isLoading} loadingPosition='end' variant="contained"
                                onClick={checkValidation}
                                sx={{
                                    backgroundColor: '#F9213B',
                                    fontSize: '1em',
                                }}
                            >
                                <span>Reset Password</span>
                            </LoadingButton>
                        </div>

                        <Grid container justifyContent="center" className='mt-3'>
                            <Link to="/Account/Login" style={{
                                color: 'white',
                                paddingLeft: '10px'
                            }}> &lt; Return to sign in</Link>
                        </Grid>

                    </SmallContainer>
                </Grid>

                <Footer />
            </Container>
        </>
    );
};

export default FForget;
