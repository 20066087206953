import { APP_ROUTE } from "@app/constants/app-routes";
import { Box, Container, Grid, Stack } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import { AiFillDashboard } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdLogOut } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteAll } from '@app/modules/auth/slice';

const TopBar = () => {

    const user = useSelector((state: any) => state.user);
    const navigation = useNavigate();
    const dispatch = useDispatch();

    function isChurch(): boolean {
        return user && user.role == 'NotForProfit';
    }

    function isBusiness(): boolean {
        return user && user.role == 'Business';
    }

    function isConsumer(): boolean {
        return user && user.role == 'Consumer';
    }

    function getDashboardLink(): string {
        if (isChurch()) return '/system/admin/ChurchDashboard';
        if (isBusiness()) return '/system/admin/BusinessDashboard';
        if (isConsumer()) return '/system/admin/ConsumerDashboard';

        return '';
    }

    function getProfileLink(): string {
        if (isChurch()) return '/system/admin/ChurchProfile';
        if (isBusiness()) return '/system/admin/BusinessProfile';
        if (isConsumer()) return '/system/admin/ConsumerProfile';

        return '';
    }

    function logout(name: string) {
        dispatch(deleteAll({}));
        navigation(name);
    }

    return (
        <>
            <Container sx={{
                maxWidth: '1437px !important',
                backgroundColor: '#161C24',
                padding: '10px',
            }}>

                <Stack direction="row">
                    <img alt="logo" src="/assets/img/logo.png" style={{
                        height: '40px',
                        display: 'block',
                    }} />

                    <img alt="logo" src="/assets/img/logo-h.png" className="ml-2"
                        style={{
                            height: '40px',
                            display: 'block',
                        }} />

                    {/* <span style={{
                        color: 'white',
                        display: 'inline-block',
                        minWidth: '200px',
                        marginLeft: '20px',
                        fontSize: '24px',
                    }}>
                        Freedom Place
                    </span> */}

                    {/* Menu Mobile */}

                    <Grid container className='d-md-none' justifyContent="right">
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary">
                                <GiHamburgerMenu />
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{
                                backgroundColor: 'black',
                                padding: '10px',
                            }}>

                                <Link className='f-anchor mr-4' to={getDashboardLink()}>
                                    <AiFillDashboard size={24} className='mr-2' />
                                    Dashboard
                                </Link>

                                <Link className='f-anchor mr-4' to={getProfileLink()}>
                                    <FaUser size={24} className='mr-2' />
                                    Profile
                                </Link>

                                <Box className='f-anchor' onClick={() => {
                                    logout(APP_ROUTE.LOGIN);
                                }}
                                >
                                    <IoMdLogOut size={24} className='mr-2' />
                                    Logout
                                </Box>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Grid>

                    {/* Menu Mobile */}

                    {/* Desktop */}

                    <div className='col d-none d-md-block'>
                        <Grid container justifyContent="right" sx={{
                            marginTop: '5px !important',
                        }}>
                            <Link className='f-anchor mr-4' to={getDashboardLink()}>
                                <AiFillDashboard size={20} className='mr-2' />
                                Dashboard
                            </Link>

                            <Link className='f-anchor mr-4' to={getProfileLink()}>
                                <FaUser size={20} className='mr-2' />
                                Profile
                            </Link>

                            <Box className='f-anchor mr-4 d-inline' onClick={() => {
                                logout(APP_ROUTE.LOGIN);
                            }}
                            >
                                <IoMdLogOut size={20} className='mr-2' />
                                Logout
                            </Box>
                        </Grid>
                    </div>

                    {/* End Desktop */}
                </Stack>

            </Container>
        </>
    );
}
export { TopBar }