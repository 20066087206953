export const Strings = {
  Actions: {
    UPDATE_USER: 'UPDATE_USER',
    DELETE_ALL: 'DELETE_ALL',
  },
  Roles: {
    CONSUMER: 'Consumer',
    BUSINESS: 'Business',
    NONPROFIT: 'NotForProfit',
    ADMIN: 'admin',
    SUPERADMIN: 'Super Administrator',
  },
  admin: {
    columnName: [
      'Branch',
      'LinkID',
      'Business Name',
      'Last Name',
      'First Name',
      'Total',
      'Wave 1',
      'Wave 2',
      'Wave 3',
      'Wave 4',
      'Country',
      'State Or Region',
      'Sign Up',
      'Referrer',
    ],
    tableData: [
      {
        branch: 'Consumer',
        linkId: '100052',
        businessName: '',
        lastName: 'Smith',
        firstName: 'Christian',
        total: '',
        wave1: '',
        wave2: '',
        wave3: '',
        wave4: '',
        country: 'United States',
        stateOrRegion: 'SC',
        signup: '3/24/2023',
        referrer: '100025',
      },
      {
        branch: 'Consumer',
        linkId: '100031',
        businessName: '',
        lastName: 'Farmer',
        firstName: 'Daniel',
        total: '',
        wave1: '',
        wave2: '',
        wave3: '',
        wave4: '',
        country: 'United States',
        stateOrRegion: 'NC',
        signup: '3/18/2023',
        referrer: '100028',
      },
      {
        branch: 'Consumer',
        linkId: '100025',
        businessName: '',
        lastName: 'Kent',
        firstName: 'Darren',
        total: '24',
        wave1: '12',
        wave2: '4',
        wave3: '4',
        wave4: '4',
        country: 'United States',
        stateOrRegion: 'NV',
        signup: '3/18/2023',
        referrer: '100000',
      },
      {
        branch: 'Consumer',
        linkId: '100037',
        businessName: '',
        lastName: 'Harris',
        firstName: 'Erin',
        total: '',
        wave1: '',
        wave2: '',
        wave3: '',
        wave4: '',
        country: 'Canada',
        stateOrRegion: 'FL',
        signup: '3/18/2023',
        referrer: '100036',
      },
      {
        branch: 'Consumer',
        linkId: '100050',
        businessName: '',
        lastName: 'Taneja',
        firstName: 'Gaurav',
        total: '',
        wave1: '',
        wave2: '',
        wave3: '',
        wave4: '',
        country: 'India',
        stateOrRegion: 'NA',
        signup: '3/24/2023',
        referrer: '100025',
      },
      {
        branch: 'Consumer',
        linkId: '100019',
        businessName: '',
        lastName: 'Jimmy',
        firstName: 'Gmail',
        total: '1',
        wave1: '1',
        wave2: '',
        wave3: '',
        wave4: '',
        country: 'Argentina',
        stateOrRegion: 'GA',
        signup: '3/14/2023',
        referrer: '100018',
      },
      {
        branch: 'Consumer',
        linkId: '100038',
        businessName: '',
        lastName: 'Brown',
        firstName: 'Jacob',
        total: '',
        wave1: '',
        wave2: '',
        wave3: '',
        wave4: '',
        country: 'Canada',
        stateOrRegion: 'NY',
        signup: '3/18/2023',
        referrer: '100026',
      },
      {
        branch: 'Consumer',
        linkId: '100026',
        businessName: '',
        lastName: 'Warren',
        firstName: 'Janet',
        total: '3',
        wave1: '2',
        wave2: '1',
        wave3: '',
        wave4: '',
        country: 'United States',
        stateOrRegion: 'CA',
        signup: '3/18/2023',
        referrer: '100025',
      },
      {
        branch: 'Consumer',
        linkId: '100013',
        businessName: '',
        lastName: 'Wimpress',
        firstName: 'Jim',
        total: '',
        wave1: '',
        wave2: '',
        wave3: '',
        wave4: '',
        country: 'United States',
        stateOrRegion: 'TX',
        signup: '3/14/2023',
        referrer: '100011',
      },
      {
        branch: 'Consumer',
        linkId: '100014',
        businessName: '',
        lastName: 'Wimpress',
        firstName: 'Jim',
        total: '4',
        wave1: '1',
        wave2: '1',
        wave3: '1',
        wave4: '1',
        country: 'United States',
        stateOrRegion: 'TX',
        signup: '3/14/2023',
        referrer: '100011',
      },
    ],
  },
  auth: {
    email_address: 'Email Address',
    password: 'Password',
    submit: 'Submit',
    login_text:
      'After you click LOGIN, you will be emailed a 6 digit verification to enter on the next page. This is necessary for now due to the level of security required for this type of enterprise.',
    login: 'Login',
    forget_password: 'Forgot Password?',
  },
  bottomLink: {
    faq: 'FAQ',
    what: 'What',
    news: 'News',
    webinar: 'Webinar',
    contact: 'Contact',
  },
  footer: {
    rights: '2023 Freedom Place. All rights reserved. ',
    termsOfUse: 'Terms of Use',
    primaryPolicy: 'Privacy Policy',
  },
  header: {
    freedom: 'Freedom Place',
    consumerhome: 'Consumer Home',
    businesshome: 'Business Home',
    churchhome: 'Church Home',
    profile: 'Your Profile',
    changePassword: 'Change Your Password',
    logout: 'Logout',
  },
  verification: {
    enterVerificationcode: 'Enter Verification Code Received Via Email',
    scoreboard: 'You will be forwarded to your Scoreboard.',
    freedomLink: 'Get your Unique Trackable FreedomLink',
    tm: 'TM',
    media: 'Post it to Social Media. Text it. Email it.',
    freedomPlace: 'Freedom Place does the rest!',
    money: 'Your Scoreboard will tell you when you are in the money!',
  },
  changePassword: {
    changePasswordTitile: 'Change Password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
  },
  dashboard: {
    welcome: 'Welcome',
    youAreMember: 'you are member number ',
    signUp: 'You have: 0 Sign Ups as of ',
    copyPasteText: 'To Text or Email Your Link - Just Copy & Paste',
    copyClipboard: 'Click to Copy to Clipboard Here',
    chooseEmail: 'Choose Text or Email Here',
    socialShareText: 'Social Share Your Link To Get SignUps',
    sharehere: 'Share Here, Just Copy & Paste:',
    facebookUrl:
      'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffreedomplace.azurewebsites.net%2Fconsumer%2Fsignup%3Fid=100057&title=I&#39;m on Freedom Place. It has changed the rules of buying and selling. We can all sign up for free, pass a link and Freedom Place does the rest.  It reverses the flow of money out of Big Media/Big Tech into the accounts of small businesses and people, just like me. SIGN UP HERE',
    twitterUrl:
      'https://twitter.com/intent/tweet?url=https://freedomplaceweb.azurewebsites.net/consumer/signup?id=100057&text=I&#39;m on Freedom Place. It has changed the rules of buying and selling. We can all sign up for free, pass a link and Freedom Place does the rest.  It reverses the flow of money out of Big Media/Big Tech into the accounts of small businesses and people, just like me. SIGN UP HERE',
    whatsappUrl:
      'https://api.whatsapp.com/send?text=I&#39;m on Freedom Place. It has changed the rules of buying and selling. We can all sign up for free, pass a link and Freedom Place does the rest.  It reverses the flow of money out of Big Media/Big Tech into the accounts of small businesses and people, just like me. SIGN UP HERE:%20https%3A%2F%2Ffreedomplace.azurewebsites.net%2Fconsumer%2Fsignup%3Fid%3D100057',
    linkedInUrl:
      'https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Ffreedomplace.azurewebsites.net%2Fconsumer%2Fsignup%3Fid=100057&title=I&#39;m on Freedom Place. It has changed the rules of buying and selling. We can all sign up for free, pass a link and Freedom Place does the rest.  It reverses the flow of money out of Big Media/Big Tech into the accounts of small businesses and people, just like me. SIGN UP HERE',
    circleUrl:
      'https://gab.com/compose?url=https://freedomplaceweb.azurewebsites.net/consumer/signup?id=100057&title=I&#39;m on Freedom Place. It has changed the rules of buying and selling. We can all sign up for free, pass a link and Freedom Place does the rest.  It reverses the flow of money out of Big Media/Big Tech into the accounts of small businesses and people, just like me. SIGN UP HERE',
    circleUrlTwo:
      'https://telegram.me/share/url?url=https://freedomplaceweb.azurewebsites.net/consumer/signup?id=100057&text=I&#39;m on Freedom Place. It has changed the rules of buying and selling. We can all sign up for free, pass a link and Freedom Place does the rest.  It reverses the flow of money out of Big Media/Big Tech into the accounts of small businesses and people, just like me. SIGN UP HERE',
    checkScored: 'Check Your Scoreboard Here',
    signUps: 'Sign Ups',
    allMoneyEarned: 'All Money Earned',
    paidMembership: 'Paid Memberships',
    deals: 'Deals',
    giftCard: 'Gift Cards',
    product: 'PRODUCTS',
    featuredProduct: 'Featured Products',
    allTime: 'All Time',
    lastThirtyDays: 'LAST 30 Days',
    lastSixtyDays: 'LAST 60 Days',
    lastNinetyDays: 'LAST 90 Days',
    totalFreedom: 'TOTAL FREEDOM PARTNER SIGN UPS',
    totalProducts: 'TOTAL PRODUCTS COMMISSIONS EARNED',
    notActiveYet: 'Not Active Yet',
    firstWave: '1ST WAVE',
    secondWave: '2ND WAVE',
    thirdWave: '3RD WAVE',
    fourthWave: '4TH WAVE',
    allFourthWave: 'PER PAID MEMBER ON ALL 4 WAVES!',
    totalEarned: 'TOTAL YOU HAVE EARNED',
    menbersPay: 'MEMBERS PAY $',
    memberShip: 'MEMBERSHIPS',
    totalpaidMember: 'TOTAL PAID MEMBERS',
    totalDealComission: 'TOTAL DEAL COMMISSIONS EARNED',
    totalgiftCardComission: 'TOTAL GIFT CARD COMMISSIONS EARNED',
    everyGiftCard:
      'You make a commission for every gift card bought by anyone in your 4 waves ... forever.',
    forever:
      'You make a commission for every deal bought by anyone in your 4 waves ... forever.',
    network: 'You earn $ off everyone in your Transaction Network.',
    everyMembership: 'Every membership fee makes you $1 each month.',
    everyNetwork:
      'Every deal, gift card and product bought by anyone in your network,',
    comissionforever: 'makes you a commission ... forever.',
    consumer: 'CONSUMER',
    commission:
      'You make a commission on any purchase bought by anyone in your 4 waves ... Forever.',
    URL: 'https://freedomplaceweb.azurewebsites.net/consumer/signup?id=100057',
  },
  profile: {
    firstName: 'First Name *',
    business: 'Business Name *',
    orgname: 'Org Name *',
    required: 'Required',
    lastName: 'Last Name *',
    email: 'Email *',
    cellPhone: 'Cell Phone *',
    country: 'Country *',
    city: 'City *',
    region: 'Region / State *',
  },
  forgetPassword: {
    forgetPasswords: 'Forgot your password?',
    resetPasswords: 'Enter your new password',
    enterEmail: 'Please enter your Email:',
    emailRequired: 'The email field is required.',
    returnToLogin: 'Return to LogIn',
  },
  resetPassword: {
    confirmPasswords: 'Confirm Password',
    resetPasswords: 'Password',
    enterEmail: 'Email Address',
    emailRequired: 'The email field is required.',
    returnToLogin: 'Return to LogIn',
  },
  sessionOut: {
    sessionTimeOut: 'SESSION TIMED OUT',
    oopsTimeOut: 'Oops, looks like your session has timed out.',
    placePortal:
      'Please close your browser window and re-launch the Freedom Place Portal.',
  },
  signUpLink: {
    emulator: 'EMULATOR',
    forYou: 'What is in it for you?',
    values: 'Values',
    contactUs: 'Contact Us',
    startToday: 'Start Today for Free',
    login: 'Login',
    referalCode: 'REFERRAL CODE REQUIRED',
    pleaseClick:
      'Oops, looks like you attempted to sign up without a valid member s link.',
    pleaseclickBelow:
      'Please click below where you can locate a Member code using GEO or Category search.',
    sorryText: 'Sorry, this function is not available yet',
  },
  sessionError: {
    unexpectedError: 'UNEXPECTED SESSION ERROR',
    oppsLook:
      'Oops, looks like the application has experienced an unexpected error.',
    pleaseClose:
      'Please close your browser window and re-launch the Freedom Place Portal.',
    problemPersist:
      'If this problem persists, please contact suport for assistance.',
  },
  signUp: {
    values: 'Values',
    faq: 'FAQ',
    what: 'What',
    webinar: 'Webinar',
    contact: 'Contact',
    profileOrganization:
      '19 Not For Profit Organizations signed up in the last 24 hours.',
    nonProfit: 'Church or Non-Profit Sign Up',
    consumer: 'Consumer Sign Up',
    business: 'Business Sign Up',
    required: 'All entries are required',
    signingWave: 'You are signing up on 1st Wave of:',
    patriot: 'Patriot Church',
    trackable: 'Get your trackable link and start signing up others.',
    requiredField: 'Required Field',
    orgName: 'ORG NAME',
    businessName: 'BUSINESS',
    consumerName: 'Consumer',
    first: 'FIRST',
    last: 'LAST',
    email: 'EMAIL',
    cell: 'CELL',
    city: 'CITY',
    state: 'STATE',
    unitedstate: 'If not residing in the United States, please select',
    createPassword: 'Create Password:',
    confirmPassowrd: 'Confirm Password:',
    passwordNotMatch: 'Passwords Do Not Match',
    passwrdContain: 'Password must be at least 8 characters',
    letter: 'and contain at least 1 (one) Capital Letter',
    number: 'and 1 (one) number.',
    certifyAge: 'Certify Age & Agree to Terms *',
    countryList: [
      'United States',
      'Canada',
      'Afghanistan',
      'Albania',
      'Algeria',
      'Andorra',
      'Angola',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Austria',
      'Azerbaijan',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bhutan',
      'Bolivia',
      'Bosnia and Herzegovina',
      'Botswana',
      'Brazil',
      'Brunei',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cabo Verde',
      'Cambodia',
      'Cameroon',
      'Central African Republic',
      'Chad',
      'Channel Islands',
      'Chile',
      'China',
      'Colombia',
      'Comoros',
      'Congo',
      'Costa Rica',
      'Côte dIvoire',
      // 'Côte d'Ivoire'
      'Croatia',
      'Cuba',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'DR Congo',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Eswatini',
      'Ethiopia',
      'Faeroe Islands',
      'Finland',
      'France',
      'French Guiana',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Greece',
      'Grenada',
      'Guatemala',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Holy See',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran',
      'Iraq',
      'Ireland',
      'Isle of Man',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kuwait',
      'Kyrgyzstan',
      'Laos',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macao',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Namibia',
      'Nepal',
      'Netherlands',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'North Korea',
      'North Macedonia',
      'Norway',
      'Oman',
      'Pakistan',
      'Panama',
      'Paraguay',
      'Peru',
      'Philippines',
      'Poland',
      'Portugal',
      'Qatar',
      'Réunion',
      'Romania',
      'Russia',
      'Rwanda',
      'Saint Helena',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Vincent and the Grenadines',
      'San Marino',
      'Sao Tome & Principe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Somalia',
      'South Africa',
      'South Korea',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'State of Palestine',
      'Sudan',
      'Suriname',
      'Sweden',
      'Switzerland',
      'Syria',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'The Bahamas',
      'Timor-Leste',
      'Togo',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'Uruguay',
      'Uzbekistan',
      'Venezuela',
      'Vietnam',
      'Western Sahara',
      'Yemen',
      'Zambia',
      'Zimbabwe',
    ],
    stateList: [
      'Not In The USA',
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming'
    ],
  },
};
