import { Strings } from '@app/constants';
import storage from 'redux-persist/lib/storage';

interface UserState {
  firstName: string;
  lastName: string;
  cellPhone: string;
  username: string;
  country: string;
  city: string;
  businessName: string;
  regionOrState: string;
  email: string;
  phone: string;
  password: string;
  token: string;
  linkId: any;
  userId: string;
  secretKey: string;
  waveMetricsModels: any;
  isFetching: boolean;
  errorMessage: string;
  role: string;
  isConsumerApiLoading: boolean;
  isWaveApiLoading: boolean;
}

const initialState: UserState = {
  firstName: '',
  lastName: '',
  cellPhone: '',
  country: '',
  city: '',
  regionOrState: '',
  email: '',
  phone: '',
  password: '',
  token: '',
  linkId: 0,
  userId: '',
  secretKey: '',
  businessName: '',
  waveMetricsModels: {},
  isFetching: true,
  errorMessage: '',
  role: '',
  username: '',
  isConsumerApiLoading: false,
  isWaveApiLoading: false,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Strings.Actions.UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case Strings.Actions.DELETE_ALL:
      return storage.removeItem('persist:root');
    default:
      return state;
  }
};

export { userReducer };
