/**
 * Root Saga
 */

import { adminSagas } from '@app/modules/admin/saga';
import { authSagas } from '@app/modules/auth/saga';
import { userSagas } from '@app/modules/dashboard/saga';

const rootSaga: any = [authSagas, userSagas, adminSagas];

export { rootSaga };
