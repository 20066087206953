import { Grid, Stack } from "@mui/material";

const Wave = (props: any) => {
    return (
        <>
            <div className="col">
                <Grid container justifyContent="center">
                    <Stack>
                        <h5 className="font-weight-bold">{props.label}</h5>
                        <div style={{
                            fontFamily: 'Cursed Timer ULiL',
                            backgroundColor: '#212B36',
                            padding: '5px',
                            fontSize: '2em',
                            borderRadius: '10px',
                            color: 'white',
                            textAlign: 'center',
                        }}>
                            {props.value}
                        </div>
                    </Stack>
                </Grid>
            </div>
        </>
    );
}
export { Wave }
