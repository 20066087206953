import React, { useState } from 'react';
import { IconButton, TextField, TextFieldProps, Tooltip } from '@mui/material';
import { TextBox } from './text-box';
import Iconify from './iconify/Iconify';

type Props = TextFieldProps & {
  hintText?: string;
};

const Input = React.forwardRef((props: Props, ref: any) => {
  const { hintText, InputProps, InputLabelProps, onFocus, onBlur, ...rest } = props;

  const [touched, setTouched] = useState(false);

  return (
    <TextBox
      ref={ref}
      variant="outlined"
      onFocus={(event) => {
        setTouched(true);
        onFocus?.(event);
      }}
      onBlur={(event) => {
        setTouched(false);
        onBlur?.(event);
      }}
      {...rest}
      InputLabelProps={{
        ...(hintText && {
          shrink: touched || !!rest.value,
          sx: { marginLeft: touched || !!rest.value ? 0 : '35px' },
        }),
        ...InputLabelProps,
      }}
      InputProps={{
        startAdornment: hintText ? (
          <Tooltip title={hintText} placement="top">
            <IconButton>
              <Iconify icon="ic:outline-info" color="white" />
            </IconButton>
          </Tooltip>
        ) : null,

        ...InputProps,
      }}
    />
  );
});

export default Input;
