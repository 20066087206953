/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * Login screen
 */

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import './auth.module.scss';
import './login.scss';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAll, login } from '../slice';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { Footer } from '@app/components/common/footer';
import { Video } from '@app/components/common/video';
import { SmallContainer } from '@app/components/common/small-container';
import { Logo } from '@app/components/common/logo';
import { TextBox } from '@app/components/common/text-box';
import Input from '@app/components/common/Input';
import { LoadingButton } from '@mui/lab';
import Iconify from '@app/components/common/iconify/Iconify';

const FLoginScreen: React.FC = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const selector = useSelector((state: any) => state.user);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    function goToHomeScreen(name: any) {
        setEmailError('');
        setPasswordError('');
        if (name === 'verification') {
            checkValidation();
        } else {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            navigate('/' + name);
        }
    }

    function checkValidation() {
        let isValid = true;
        const emailRegex = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (!emailRegex.test(email)) {
            setEmailError('Email is required.');
            isValid = false;
        } else {
            setEmailError('');
        }
        if (password.trim() === '') {
            setPasswordError('Password is required.');
            isValid = false;
        }

        if (!isValid) return;
        dispatch(deleteAll({}));
        let emailLower = email.toLowerCase();

        dispatch(
            login({
                email: emailLower,
                password,
                grand_type: 'password',
                navigate,
                setPasswordError,
                setisLoading,
            }),
        );
    }

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
    };

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            console.log('enter press here! ');
            checkValidation();
        }
    }

    useEffect(() => {
        // throw new Error('This is an intentional error');
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (selector.token) {
            if (selector.role.toLowerCase() === 'consumer') {
                //navigate(APP_ROUTE.DASHBOARDS.USER);
            } else if (selector.role.toLowerCase() === 'business') {
                //navigate(APP_ROUTE.DASHBOARDS.BUSINESS);
            } else {
                //navigate(APP_ROUTE.DASHBOARDS.NONPROFIT);
            }
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Sign In - Freedom Place</title>
                <style>
                    {
                        'body { background-color: black; }'
                    }
                </style>
            </Helmet>

            <Container sx={{
                paddingBottom: '40px',
                maxWidth: '1400px !important',
                minHeight: '100vh !important',
                position: 'relative',
                overflow: 'hidden',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
            }}>
                <Video />

                <Grid container justifyContent="center" sx={{
                    width: '100%',
                }}>
                    <SmallContainer>
                        <Logo />

                        <Grid container spacing={2} justifyContent="center" >
                            {/* Mobile */}

                            <h5 className="d-block d-md-none" style={{
                                fontSize: '16px',
                                marginTop: '20px',
                            }}>
                                Welcome To Freedom Place Members
                            </h5>

                            <div className='w-100'></div>

                            <h5 className="d-block d-md-none mb-4" style={{
                                fontSize: '14px',
                                marginTop: '0px',
                            }}>
                                Get 10 & Win.
                            </h5>

                            {/* End Mobile */}

                            <Typography variant="h4" marginTop="20px" className='d-none d-md-block'
                                style={{
                                    fontSize: '23px',
                                }}
                            >
                                Welcome To Freedom Place Members
                            </Typography>

                            <div className='w-100'></div>

                            <Typography variant="h4" marginBottom="20px" className='d-none d-md-block'
                                style={{
                                    fontSize: '18px',
                                }}
                            >
                                Get 10 & Win.
                            </Typography>
                        </Grid>

                        <Stack spacing={3} sx={{
                            width: '100%',
                        }}>

                            <Stack>
                                <TextBox label="Email Address" value={email}
                                    onChange={(event) => {
                                        handleEmailChange(event);
                                        return void 0;
                                    }} />

                                {
                                    emailError.length > 0 && (
                                        <div className='alert alert-danger'>{emailError}</div>
                                    )
                                }
                            </Stack>

                            {/* Password */}

                            <Stack>
                                <Input
                                    label="Password" type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} color="white" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={password}
                                    onChange={(event) => {
                                        handlePasswordChange(event);
                                        return void 0;
                                    }}
                                    onKeyPress={handleKeyPress}
                                >
                                </Input>

                                {
                                    passwordError.length > 0 && (
                                        <div className='alert alert-danger'>{passwordError}</div>
                                    )
                                }
                            </Stack>

                            {/* End Password */}

                            <Grid container spacing={2} justifyContent="right" sx={{
                                paddingRight: '20px',
                            }}>
                                <Link to="/Account/Forget" style={{
                                    color: 'white',
                                }}>Forgot Password?</Link>
                            </Grid>

                            <LoadingButton loading={isLoading} loadingPosition='end' variant="contained"
                                sx={{
                                    backgroundColor: 'white !important',
                                    color: 'black !important',
                                    fontSize: '1em',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => {
                                    goToHomeScreen('verification');
                                }}
                            >
                                <span>Log In</span>
                            </LoadingButton>

                            <Box sx={{
                                backgroundColor: '#212B36',
                                borderRadius: '20px',
                                padding: '20px',
                                marginBottom: '30px',
                            }}>
                                If you have a problem, email members@freedomplace.com
                                or call (972) 646-1731
                            </Box>

                        </Stack>

                    </SmallContainer>

                </Grid>

                <Footer />
            </Container>
        </>
    );
};

export default FLoginScreen;
