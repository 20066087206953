import { withStyles } from "@material-ui/core";
import { TextField } from "@mui/material";

export const TextBox = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'whitesmoke',
        },
        '& label': {
            color: 'gray',
        },
        '& .MuiOutlinedInput-input': {
            color: 'white',
        },
        '& ::placeholder': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#3D464F',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
})(TextField);

export const DateTextBox = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'whitesmoke',
        },
        '& label': {
            color: 'gray',
        },
        '& .MuiOutlinedInput-input': {
            color: 'white',
        },
        '& ::placeholder': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            height: '40px !important',
            backgroundColor: '#2A3540',
            '& fieldset': {
                borderColor: '#3D464F',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
})(TextField);
