/* eslint-disable react/no-unknown-property */
import { useEffect, useState } from 'react';
import { APP_ROUTE, Strings } from '@app/constants';
import './profile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, getProfile, getUserWaves, updateInitialState, updateUserProfile } from '@app/modules/dashboard/slice';
import { TopBar } from '@app/modules/dashboard/view/top-bar';
import { Container, Grid, IconButton, InputAdornment, MenuItem, Stack } from '@mui/material';
import { BigContainer } from '@app/components/common/big-container';
import { Footer2 } from '@app/components/common/footer';
import { Helmet } from 'react-helmet-async';
import { TextBox } from '@app/components/common/text-box';
import { LoadingButton } from '@mui/lab';
import Input from '@app/components/common/Input';
import { enqueueSnackbar } from 'notistack';
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import Iconify from '@app/components/common/iconify/Iconify';

const FProfileScreen = () => {
    const selector = useSelector((state: any) => state.user);
    const [selectedCountry, setCountry] = useState(selector.country);
    const [countryError] = useState('');
    const [selectState, setState] = useState(selector.regionOrState);
    const [firstName, setFirstName] = useState(selector.firstName);
    const [firstNameError, setFirstNameError] = useState('');
    const [lastName, setLastName] = useState(selector.lastName);
    const [lastNameError, setLastNameError] = useState('');
    const [busname, setBusName] = useState(selector.businessName);
    const [email, setEmail] = useState(selector.email);
    const [emailError, setEmailError] = useState('');
    const [cellPhone, setCellPhone] = useState(selector.cellPhone);
    const [cellPhoneError, setCellPhoneError] = useState('');
    const [city, setCity] = useState(selector.city);
    const [cityError, setCityError] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [, setUpdateMessage] = useState('');
    const countryList = Strings.signUp.countryList;
    const stateList = Strings.signUp.stateList;

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [currentPassword, setInputState] = useState({ value: '', error: '' });
    const [newPassword, setInputStateNewPassword] = useState({
        value: '',
        error: '',
    });
    const [confirmPassword, setInputStateConfirmPassword] = useState({
        value: '',
        error: '',
    });
    const [, setError] = useState('');

    const dispatch = useDispatch();
    const navigator = useNavigate();
    const user = useSelector((state: any) => state.user);
    let isCalled = false;

    function handleChange(event: any, name: string): void {
        if (name === 'Current') {
            setInputState((prevState) => ({
                ...prevState,
                value: event.target.value,
            }));
        }
        if (name === 'New') {
            setInputStateNewPassword((prevState) => ({
                ...prevState,
                value: event.target.value,
            }));
        }
        if (name === 'Confirm') {
            setInputStateConfirmPassword((prevState) => ({
                ...prevState,
                value: event.target.value,
            }));
        }
        console.log(currentPassword, newPassword, confirmPassword);
    }

    function handleFirstNameChange(event: any): void {
        setFirstName(event.target.value);
    }

    function handleLastNameChange(event: any): void {
        setLastName(event.target.value);
    }

    function handleEmailChange(event: any): void {
        setEmail(event.target.value);
    }

    function handleCellChange(event: any): void {
        setCellPhone(event.target.value);
    }

    function handleCountryChange(event: any): void {
        setCountry(event.target.value);
    }

    function handleCityChange(event: any): void {
        setCity(event.target.value);
    }

    function handleStateChange(event: any): void {
        setState(event.target.value);
    }


    function onSubmit(): void {
        let isValid = true;
        setFirstNameError('');
        setLastNameError('');
        setCellPhoneError('');
        setCityError('');

        if (!firstName || !firstName.length) {
            setFirstNameError('First name is required.');
            isValid = false;
        }

        if (!lastName || !lastName.length) {
            setLastNameError('Last name is required.');
            isValid = false;
        }

        if (!email || !email.length) {
            setEmailError('Email is required.');
            isValid = false;
        }

        if (!cellPhone || !cellPhone.length) {
            setCellPhoneError('Cell phone is required.');
            isValid = false;
        }

        
        if (cellPhone && cellPhone.length && cellPhone.trim().length < 6) {
            setCellPhoneError('Phone must have at least 6 numbers.');
            isValid = false;
        }

        if (!city || !city.length) {
            setCityError('City is required.');
            isValid = false;
        }

        if (!isValid) return;

        dispatch(
            updateUserProfile({
                firstname: firstName,
                lastname: lastName,
                city,
                country: selectedCountry,
                stateOrRegion: selectState,
                email: email.toLowerCase(),
                cellphone: cellPhone,
                state: selectState,
                setisLoading,
                businessName: busname,
                uniqueLinkId: selector.linkId,
                role: selector.role,
                setUpdateMessage,
            }),
        );
    }

    useEffect(() => {
        if (user.token === undefined || user.token.length <= 0) {
            navigator(APP_ROUTE.LOGIN);
        }
        console.log('getProfileNonDashboard', user.isFetching);
        // if (Object.keys(user.waveMetricsModels).length <= 0) {
        //   dispatch(updateInitialState({}));
        // }
        if (!isCalled) {
            dispatch(updateInitialState({}));
            dispatch(getProfile({ navigator }));
            dispatch(getUserWaves({}));
            isCalled = true;
        }
    }, []);

    const showError = (s: string) => {
        console.log('show error', s);
        setError(s);
        enqueueSnackbar(s, {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            variant: "error",
        });
    }

    function onChangePassword(): void {
        let isError = false;
        setError('');
        setInputState((prevState) => ({
            ...prevState,
            error: '',
        }));
        setInputStateNewPassword((prevState) => ({
            ...prevState,
            error: '',
        }));
        setInputStateConfirmPassword((prevState) => ({
            ...prevState,
            error: '',
        }));
        if (currentPassword.value === '') {
            setInputState((prevState) => ({
                ...prevState,
                error: 'The current password field is required.',
            }));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            isError = true;
        }
        if (newPassword.value === '') {
            setInputStateNewPassword((prevState) => ({
                ...prevState,
                error: 'The new password is required.',
            }));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            isError = true;
        }
        if (confirmPassword.value === '') {
            setInputStateConfirmPassword((prevState) => ({
                ...prevState,
                error: 'Confirm new password is required.',
            }));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            isError = true;
        }
        if (newPassword.value !== confirmPassword.value) {
            setInputStateConfirmPassword((prevState) => ({
                ...prevState,
                error: 'The new password and confirmation password do not match.',
            }));
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            isError = true;
        }

        if (!isError) {
            dispatch(
                changePassword({
                    currentPassword: currentPassword.value,
                    newPassword: newPassword.value,
                    setisLoading,
                    setError: showError,
                    setInputStateNewPassword,
                    setInputStateConfirmPassword,
                    setInputState,
                    setUpdateMessage,
                }),
            );
        }
    }

    useEffect(() => {
        console.log('selector', selector);
        setFirstName(selector.firstName);
        setLastName(selector.lastName);
        setEmail(selector.email);
        setCellPhone(selector.cellPhone);
        setCountry(selector.country);
        setCity(selector.city);
        setState(selector.regionOrState);
        setBusName(selector.businessName);
    }, [selector]);

    return (
        <>
            <Helmet>
                <title>Sign Up - Freedom Place</title>
                <style>
                    {
                        'body { background-color: black; }'
                    }
                </style>
            </Helmet>

            <TopBar />

            <Container sx={{
                paddingBottom: '40px',
                maxWidth: '1437px !important',
                minHeight: '100vh !important',
                position: 'relative',
                overflow: 'hidden',
                paddingLeft: '0px !important',
                paddingRight: '0px !important'
            }}>

                <Grid container justifyContent="center" sx={{
                    width: '100%',
                }}>

                    <BigContainer sx={{

                    }}>
                        <div className='w-100 mb-3'>
                            <h2>Profile</h2>
                            <div>
                                User . {firstName} {lastName}
                            </div>
                        </div>

                        <div className='row w-100'>
                            {/* <div className='col-4 pl-0'>
                                <Stack style={{
                                    backgroundColor: '#212B36',
                                    borderRadius: '10px',
                                    padding: '30px',
                                }}>

                                    <Grid container spacing={2} justifyContent="center" className='mt-3'>
                                        <IoMdPerson size={20}/>
                                    </Grid>

                                    <Grid container spacing={2} justifyContent="center"
                                        sx={{
                                            paddingTop: '20px',
                                        }}
                                    >
                                        <Stack>
                                            <div className='mt-3'>
                                                Allowed *.jpeg, *.jpg, *.png, *.gif
                                            </div>

                                            <div style={{
                                                textAlign: 'center',
                                            }}>
                                                Max size of 3MB
                                            </div>
                                        </Stack>

                                    </Grid>

                                </Stack>

                            </div> */}



                            <div className='col pr-0'>
                                <div>

                                    {/* Profile */}

                                    <Stack style={{
                                        backgroundColor: '#212B36',
                                        borderRadius: '10px',
                                        padding: '30px',
                                    }}>
                                        <h5 className='mb-4'>
                                            Your Profile
                                        </h5>

                                        <div className='row'>
                                            <div className='col'>
                                                {/* First Name */}

                                                <Stack>

                                                    <TextBox label="First Name"
                                                        value={firstName}
                                                        onChange={handleFirstNameChange}
                                                    />

                                                    {
                                                        firstNameError.length > 0 && (
                                                            <div className='alert alert-danger'>{firstNameError}</div>
                                                        )
                                                    }

                                                </Stack>

                                                {/* End First Name */}
                                            </div>

                                            {/* Last Name */}

                                            <div className='col'>


                                                <Stack>
                                                    <TextBox label="Last Name"
                                                        value={lastName}
                                                        onChange={handleLastNameChange}
                                                    />

                                                    {
                                                        lastNameError.length > 0 && (
                                                            <div className='alert alert-danger'>{lastNameError}</div>
                                                        )
                                                    }
                                                </Stack>

                                            </div>

                                            {/* End Last Name */}

                                            <div className='w-100'></div>

                                            {/* Email */}

                                            <div className='col mt-3'>
                                                <Stack>

                                                    <TextBox label="Email" tabIndex={parseInt('-1')} style={{
                                                        pointerEvents: 'none',
                                                    }}
                                                        value={email}
                                                        onChange={handleEmailChange}
                                                        onKeyPress={(e) => { e.preventDefault(); return false }}
                                                        onKeyDown={(e) => { e.preventDefault(); return false }}
                                                    />

                                                    {
                                                        emailError.length > 0 && (
                                                            <div className='alert alert-danger'>{emailError}</div>
                                                        )
                                                    }

                                                </Stack>
                                            </div>

                                            {/* End Email */}

                                            {/* Cell */}

                                            <div className='col mt-3'>
                                                <Stack>

                                                    <TextBox label="Cell Phone"
                                                        value={cellPhone} type='number'
                                                        onChange={handleCellChange}
                                                    />

                                                    {
                                                        cellPhoneError.length > 0 && (
                                                            <div className='alert alert-danger'>{cellPhoneError}</div>
                                                        )
                                                    }

                                                </Stack>
                                            </div>

                                            {/* End Cell */}

                                            <div className='w-100'></div>

                                            {/* Country */}

                                            <div className='col mt-3'>
                                                <Stack>
                                                    <TextBox variant="outlined" label="Country" select
                                                        value={selectedCountry}
                                                        onChange={handleCountryChange}
                                                    >
                                                        {countryList.map((item) => (
                                                            <MenuItem key={item} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </TextBox>

                                                    {
                                                        countryError.length > 0 && (
                                                            <div className='alert alert-danger'>{countryError}</div>
                                                        )
                                                    }

                                                </Stack>

                                            </div>

                                            {/* End Country */}

                                            {/* State */}

                                            <div className='col mt-3'>
                                                <Stack>
                                                    <TextBox variant="outlined" label="State" select
                                                        value={selectState}
                                                        onChange={handleStateChange}
                                                    >
                                                        <MenuItem key="Select Your State" value="Select Your State">Select Your State</MenuItem>

                                                        {stateList.map((item) => (
                                                            <MenuItem key={item} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </TextBox>
                                                </Stack>

                                            </div>

                                            {/* End State */}

                                            <div className='w-100'></div>

                                            {/* City */}

                                            <div className='col mt-3'>
                                                <Stack>

                                                    <TextBox label="City"
                                                        value={city}
                                                        onChange={handleCityChange}
                                                    />

                                                    {
                                                        cityError.length > 0 && (
                                                            <div className='alert alert-danger'>{cityError}</div>
                                                        )
                                                    }

                                                </Stack>
                                            </div>

                                            {/* End City */}

                                        </div>

                                        <div className='row mt-3 pr-3'>
                                            <div className='col'></div>
                                            <LoadingButton loading={isLoading} loadingPosition='end' variant="contained"
                                                onClick={onSubmit}
                                                sx={{
                                                    backgroundColor: '#F9213B',
                                                    fontSize: '1em',
                                                }}
                                            >
                                                <span>Update</span>
                                            </LoadingButton>
                                        </div>

                                    </Stack>

                                    {/* End Profile */}

                                    <Stack style={{
                                        backgroundColor: '#212B36',
                                        borderRadius: '10px',
                                        padding: '30px',
                                        marginTop: '30px',
                                    }}>
                                        <h5 className='mb-4'>
                                            Update Your Password
                                        </h5>

                                        {/* Password */}

                                        <Stack>
                                            <Input
                                                label="Password" type={showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} color="white" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={currentPassword.value}
                                                onChange={(event) => {
                                                    handleChange(event, 'Current');
                                                    return void 0;
                                                }}
                                            >
                                            </Input>

                                            {
                                                currentPassword.error.length > 0 && (
                                                    <div className='alert alert-danger'>{currentPassword.error}</div>
                                                )
                                            }
                                        </Stack>

                                        {/* End Password */}

                                        {/* New Password */}

                                        <Stack className='mt-4'>
                                            <Input
                                                label="New Password" type={showNewPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                                                                <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} color="white" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={newPassword.value}
                                                onChange={(event) => {
                                                    handleChange(event, 'New');
                                                    return void 0;
                                                }}
                                            >
                                            </Input>

                                            {
                                                newPassword.error.length > 0 && (
                                                    <div className='alert alert-danger'>{newPassword.error}</div>
                                                )
                                            }
                                        </Stack>

                                        {/* End New Password */}

                                        <div style={{
                                            color: 'gray',
                                            fontSize: '12px',
                                            paddingTop: '5px',
                                        }}>
                                            <BsFillInfoCircleFill />
                                            <span className='ml-2'>Password must be at least 8 characters and contain at least 1 (one) capital letter and 1 (one) number</span>
                                        </div>

                                        {/* Confirm Password */}

                                        <Stack className='mt-4'>
                                            <Input
                                                label="Confirm Password" type={showConfirmPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                                                                <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} color="white" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={confirmPassword.value}
                                                onChange={(event) => {
                                                    handleChange(event, 'Confirm');
                                                    return void 0;
                                                }}
                                            >
                                            </Input>

                                            {
                                                confirmPassword.error.length > 0 && (
                                                    <div className='alert alert-danger'>{confirmPassword.error}</div>
                                                )
                                            }
                                        </Stack>

                                        {/* End Confirm Password */}

                                        <div className='row mt-3 pr-3'>
                                            <div className='col'></div>
                                            <LoadingButton loading={isLoading} loadingPosition='end' variant="contained"
                                                onClick={onChangePassword}
                                                sx={{
                                                    backgroundColor: '#F9213B',
                                                    fontSize: '1em',
                                                }}
                                            >
                                                <span>Save Changes</span>
                                            </LoadingButton>
                                        </div>

                                    </Stack>
                                </div>
                            </div>



                        </div>
                    </BigContainer>

                    {/* Footer */}

                    <Grid container spacing={2} className='p-1 p-md-3'
                        justifyContent="center" sx={{
                            position: 'relative',
                            marginTop: '40px',
                            marginLeft: '10px',
                            maxWidth: '1080px',
                            color: 'white',
                        }}>
                        <Footer2 />
                    </Grid>

                    {/* End Footer */}

                </Grid>

            </Container>
        </>
    );
};

export default FProfileScreen;
