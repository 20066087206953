import { useState } from "react";

export default function Gab(props: any) {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    return (
        <svg width={props.size} height={props.size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <path d="M8.30838 9.85596H6.85845V10.248V10.64L6.61679 10.472C6.13348 9.96796 5.40851 9.63196 4.62313 9.63196C4.5023 9.63196 4.38147 9.63196 4.38147 9.63196C4.32106 9.63196 4.20023 9.63196 4.0794 9.63196C0.696212 9.79996 -1.17662 13.664 0.81704 16.408C2.20656 18.312 5.10644 18.704 6.67721 17.248L6.85845 17.08V17.472C6.85845 18.032 6.79803 18.312 6.61679 18.704C6.01265 20.048 4.13981 20.384 2.50863 19.432C2.38781 19.376 2.26698 19.32 2.26698 19.32C2.20656 19.376 1.11911 21.28 1.11911 21.336C1.11911 21.392 1.42118 21.56 1.60242 21.672C2.93153 22.456 4.98561 22.624 6.61679 22.12C8.24797 21.616 9.33542 20.328 9.6375 18.536C9.75832 17.92 9.75832 18.088 9.75832 13.832V9.85596H8.30838ZM5.83141 15.456C4.5023 16.128 2.87112 15.232 2.87112 13.832C2.87112 12.656 4.20023 11.76 5.40851 12.096C7.1001 12.544 7.40217 14.616 5.83141 15.456Z" fill={isHovered ? props.colorHover : props.color} />
            <path d="M25.3333 9.91198C23.8833 9.35198 22.3126 9.57598 21.2855 10.472L21.1043 10.64V8.11998V5.59998H19.6543H18.2044V11.76V17.92H19.6543H21.1043V17.528V17.136L21.2855 17.304C22.7355 18.648 25.4541 18.368 26.904 16.8C28.8977 14.504 28.0519 10.976 25.3333 9.91198ZM24.1854 15.4C22.9167 16.184 21.1043 15.288 21.1043 13.832C21.1043 12.656 22.2522 11.76 23.4604 11.984C25.2124 12.376 25.6958 14.448 24.1854 15.4Z" fill={isHovered ? props.colorHover : props.color} />
            <path d="M18.1202 12.6001C17.6973 10.5281 16.2474 9.52006 13.8308 9.63206C12.8038 9.68806 11.4142 10.0241 10.9309 10.4161L10.8705 10.4721L11.2934 11.4241C11.5351 11.9281 11.7163 12.3761 11.7163 12.3761C11.7163 12.3761 11.7767 12.3761 11.8371 12.3201C13.4683 11.5361 15.0995 11.8161 15.2203 12.9361V13.0481L14.9787 12.9921C13.1662 12.5441 11.1726 12.9361 10.3268 13.8881C9.23932 15.1201 9.78305 17.1361 11.3538 17.8641C12.6225 18.4241 14.1329 18.2001 15.0391 17.4161L15.1599 17.3041V17.6401V17.9761H16.6098H18.0598V15.7361C18.241 13.2721 18.241 13.0481 18.1202 12.6001ZM15.3411 14.7841C15.2807 15.1761 15.0391 15.6241 14.737 15.9041C14.012 16.5201 12.9246 16.2961 12.8038 15.5121C12.6829 14.9521 13.1662 14.5601 14.012 14.5041H14.1329H14.3745C14.6766 14.5041 14.9787 14.5601 15.2203 14.6161L15.4016 14.6721L15.3411 14.7841Z" fill={isHovered ? props.colorHover : props.color} />
        </svg>
    );
}

