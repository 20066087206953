/**
 * App colors
 */

// NOTE: If we do any changes here run `yarn run sync-colors-scss`
// To update colors.scss file with new changes
export const primary = '#284F99';
export const secondary = '#D85A30';
export const transparent = 'transparent';
export const background = '#FFFFFF';
export const surface = '#FFFFFF';
export const onSecondary = '#FFFFFF';
