/* eslint-disable react/no-unknown-property */
import React from 'react';
import './session-out.scss';
import { Strings } from '@app/constants';
import Footer from '@app/components/footer/footer';

const SessionOutScreen: React.FC = () => {
  return (
    <div className="header">
      <div
        className="container"
        style={{ border: 'none', fontFamily: 'Open Sans' }}
      >
        <div className="form_padC" style={{ textAlign: 'center' }}>
          <p style={{ fontWeight: 'bold', color: 'red' }}>
            {Strings.sessionOut.sessionTimeOut}
          </p>
          <div style={{ fontWeight: 'large', color: '#003a63' }}>
            {Strings.sessionOut.oopsTimeOut}
            <br />
            <br />
            {Strings.sessionOut.placePortal}
            <br />
            <br />
            <div
              style={{
                verticalAlign: 'middle',
                backgroundColor: 'white',
                paddingTop: '50px',
                paddingBottom: '50px',
              }}
            >
              <img
                src={require('../../../assets/images/FreedomPlaceLogo.png')}
                alt="Freedom Place"
                width="400"
                height="400"
              />
              <br />
            </div>
          </div>
          <br />
        </div>
      </div>
      <Footer>
        <></>
      </Footer>
    </div>
  );
};

export default SessionOutScreen;
