/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-void */
/* eslint-disable react/no-unknown-property */
import { Strings } from '@app/constants';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { admindata } from './slice';
import './admin.scss';
import { CircularProgress } from '@mui/material';
import Papa from 'papaparse';
import { Header } from '@app/components/header';

const AdminScreen: React.FC = () => {
  const cols = [
    {
      d: 'Branch', n: 'Branch',
    },
    {
      d: 'Email', n: 'Email',
    },
    {
      d: 'LinkID', n: 'LinkID',
    },
    {
      d: 'Business Name', n: 'BusinessName',
    },
    {
      d: 'Last Name', n: 'LastName',
    },
    {
      d: 'First Name', n: 'FirstName',
    },
    {
      d: 'Total', n: 'Total',
    },
    {
      d: 'Wave 1', n: 'Wave1',
    },
    {
      d: 'Wave 2', n: 'Wave2',
    },
    {
      d: 'Wave 3', n: 'Wave3',
    },
    {
      d: 'Wave 4', n: 'Wave4',
    },
    {
      d: 'Country', n: 'Country',
    },
    {
      d: 'State Or Region', n: 'StateOrRegion',
    },
    {
      d: 'Sign Up', n: 'SignUpDate',
    },
    {
      d: 'Referrer', n: 'ReferredBy',
    },
  ];

  const [tableData, setTableData]: any[] = useState([]);
  const [columnData, setcolumnData]: any[] = useState(cols);
  const [pages, setPages]: any[] = useState([]);
  const [isOpen, setIsOpen]: any[] = useState(false);
  const [pageSize, setPageSize]: any[] = useState(10);
  const [currentPage, setCurrentPage]: any[] = useState(1);
  const [sort, setSort] = useState('');
  const [role, setRole] = useState(Strings.Roles.CONSUMER);
  const [direction, setDirection] = useState(0);
  const dispatch = useDispatch();
  const selector = useSelector((state: any) => state.user);
  const containerRef = useRef<HTMLElement>(null);
  const dropRef = useRef<HTMLElement>(null);

  const exportCSV = () => {
    console.log('tableData', tableData);
    /* const fields = ['Branch', 'Email', 'LinkID', 'Business Name', 'Last Name', 'First Name', 'Total',
      'Wave 1', 'Wave 2', 'Wave 3', 'Wave 4', 'Country', 'State Or Region', 'Sign Up', 'Referrer'
    ]; */
    const csv = Papa.unparse(tableData);
    const blob = new Blob([csv]);
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = "CSV Export File.csv";
    document.body.appendChild(a);
    a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    document.body.removeChild(a);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const handleClickOutside = (event: any) => {
      console.log(event);
      if (
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        !dropRef.current?.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  useEffect(() => {
    setCurrentPage(1);
    setIsOpen(false);
    setTableData([]);
    dispatch(
      admindata({
        branch: role,
        sort: sort,
        direction: direction,
        setTableData,
        setPages,
        pageSize,
      }),
    );
    // setTableData(Strings.admin.tableData);
    //setcolumnData(Strings.admin.columnName);
  }, [role, sort, direction]);

  /* function getData(role: string) {
    setTableData([]);
    setCurrentPage(1);
    setPages([]);
    dispatch(
      admindata({
        branch: Strings.Roles.CONSUMER,
        sort: '',
        direction: 0,
        setTableData,
        setPages,
        pageSize,
      }),
    );
  } */

  function sortData(name: string) {
    let new_d = direction == 0 ? 1 : 0;
    setDirection(new_d);
    setSort(name);
  }

  function getConsumerData() {
    setRole(Strings.Roles.CONSUMER);
    //getData(Strings.Roles.CONSUMER);
  }

  function getBusinessData() {
    setRole(Strings.Roles.BUSINESS);
    //getData(Strings.Roles.BUSINESS);
  }

  function getChurchData() {
    setRole(Strings.Roles.NONPROFIT);
    //getData(Strings.Roles.NONPROFIT);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function formatDate(originalDate: string) {
    const dateObject = new Date(originalDate);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const year = dateObject.getFullYear();
    return `${month}/${day}/${year}`;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function changePage(index: number) {
    setCurrentPage(index + 1);
  }

  return (
    <div>
      <Header role={'admin'}>
        <></>
      </Header>
      <div
        className="jumbotron dashboard-padding-top"
        style={{ padding: '10px' }}
        ref={containerRef as React.RefObject<HTMLDivElement>}
      >
        <div className="container text-center" style={{ padding: '10px' }}>
          <form
            method="post"
            action="https://freedomplaceweb.azurewebsites.net/system/admin/AdminDashboard"
            id="form1"
          >
            <div
              style={{
                backgroundColor: 'black',
                color: 'white',
                padding: '10px',
                borderRadius: '15px',
              }}
            >
              <div className="row" style={{ padding: '10px' }}>
                <div className="col-sm-12">
                  <span
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '14px',
                    }}
                  >
                    Welcome&nbsp;
                    <span
                      id="MainContent_loginUserFullName"
                      title="TIMJIM"
                      className="navbar-text-100"
                    >
                      Timothy Dillard {selector.firstName} {selector.lastName}
                    </span>
                  </span>
                </div>
              </div>

              <div className="row" style={{ padding: '15px' }}>
                <div
                  className="col-sm-4"
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                  <input
                    type="button"
                    name="ctl00$MainContent$btnConsumer"
                    value="CONSUMER ACTIVITY"
                    id="MainContent_btnConsumer"
                    className="btn btn-primary"
                    onClick={getConsumerData}
                    style={{ fontSize: '14pt' }}
                  />
                </div>
                <div
                  className="col-sm-4"
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                  <input
                    type="button"
                    name="ctl00$MainContent$btnBusiness"
                    value="BUSINESS ACTIVITY"
                    id="MainContent_btnBusiness"
                    className="btn btn-primary"
                    onClick={getBusinessData}
                    style={{ fontSize: '14pt' }}
                  />
                </div>
                <div
                  className="col-sm-4"
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                  <input
                    type="button"
                    name="ctl00$MainContent$btnNonProfit"
                    value="NON-PROFIT ACTIVITY"
                    id="MainContent_btnNonProfit"
                    className="btn btn-primary"
                    onClick={getChurchData}
                    style={{ fontSize: '14pt' }}
                  />
                </div>
              </div>

              <div className="row" style={{ padding: ' 0px' }}>
                <div
                  className="col-sm-12"
                  style={{
                    textAlign: 'center',
                    paddingLeft: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  <div
                    className="RadAjaxPanel"
                    id="ctl00_MainContent_ctl00_MainContent_RadGrid1Panel"
                    style={{ display: 'block' }}
                  >
                    <div
                      id="ctl00_MainContent_RadGrid1"
                      className="RadGrid RadGrid_Black"
                      style={{
                        borderColor: 'White',
                        fontFamily: 'Arial',
                        fontSize: '12px',
                      }}
                    >
                      <table
                        className="rgMasterTable rgClipCells"
                        id="ctl00_MainContent_RadGrid1_ctl00_TopPager"
                        style={{
                          fontSize: '12px',
                          width: '100%',
                          tableLayout: 'fixed',
                          overflow: 'hidden',
                          emptyCells: 'show',
                        }}
                      >
                        <thead>
                          <tr className="rgCommandRow">
                            <td
                              className="rgCommandCell"
                              colSpan={parseInt('14')}
                            >
                              <table
                                className="rgCommandTable"
                                summary="Command item for additional functionalities for the grid like adding a new record and exporting."
                                style={{ width: '100%' }}
                              >
                                <caption>
                                  <span style={{ display: 'none' }}>
                                    Command item
                                  </span>
                                </caption>
                                <thead>
                                  <tr>
                                    <th scope="col">Command item</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td align="left">&nbsp;</td>
                                    <td align="right">
                                      {/* <input
                                        type="button"
                                        name="ctl00$MainContent$RadGrid1$ctl00$ctl02$ctl00$RefreshButton"
                                        value=" "
                                        id="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl00_RefreshButton"
                                        title="Refresh"
                                        className="rgRefresh"
                                      /> */}
                                      {/* <a
                                        id="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl00_RebindGridButton"
                                        title="Refresh"
                                        href="javascript:__doPostBack(&#39;ctl00$MainContent$RadGrid1$ctl00$ctl02$ctl00$RebindGridButton&#39;,&#39;&#39;)"
                                      >
                                        Refresh
                                      </a> */}
                                      &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                      <input
                                        type="button"
                                        name="ctl00$MainContent$RadGrid1$ctl00$ctl02$ctl00$ExportToExcelButton"
                                        value=" "
                                        id="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl00_ExportToExcelButton"
                                        title="Export to Excel"
                                        className="rgExpXLS"
                                      />
                                      &nbsp;&nbsp;
                                      <input
                                        type="button"
                                        name="ctl00$MainContent$RadGrid1$ctl00$ctl02$ctl00$ExportToCsvButton"
                                        value=" "
                                        id="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl00_ExportToCsvButton"
                                        title="Export to CSV"
                                        className="rgExpCSV"
                                        onClick={() => {
                                          exportCSV();
                                        }}
                                      />
                                      &nbsp;&nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr className="rgPager">
                            <td colSpan={parseInt('14')}>
                              <table
                                summary="Data pager which controls on which page is the RadGrid control."
                                style={{ width: '100%', borderSpacing: '0' }}
                              >
                                <caption>
                                  <span style={{ display: 'none' }}>
                                    Data pager
                                  </span>
                                </caption>
                                <thead>
                                  <tr>
                                    <th scope="col">Data pager</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="rgStatus">
                                      <div
                                        id="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl01_statusPanel"
                                        style={{ visibility: 'hidden' }}
                                      >
                                        &nbsp;
                                      </div>
                                    </td>
                                    <td className="rgPagerCell NextPrevAndNumeric">
                                      <div className="rgWrap rgArrPart1">
                                        <input
                                          type="button"
                                          name="ctl00$MainContent$RadGrid1$ctl00$ctl02$ctl01$ctl02"
                                          value=" "
                                          title="First Page"
                                          className="rgPageFirst"
                                          onClick={() => {
                                            setCurrentPage(1);
                                          }}
                                        />
                                        <input
                                          type="button"
                                          name="ctl00$MainContent$RadGrid1$ctl00$ctl02$ctl01$ctl03"
                                          value=" "
                                          title="Previous Page"
                                          className="rgPagePrev"
                                          onClick={() => {
                                            if (currentPage > 1) {
                                              setCurrentPage(currentPage - 1);
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="rgWrap rgNumPart">
                                        {pages.map((item: any, index: any) => {
                                          return (
                                            // eslint-disable-next-line react/jsx-key
                                            <a
                                              className={
                                                currentPage === index + 1
                                                  ? 'rgCurrentPage'
                                                  : ''
                                              }
                                              // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                                              onClick={() => changePage(index)}
                                            >
                                              <span>{index + 1}</span>
                                            </a>
                                          );
                                        })}
                                      </div>
                                      <div className="rgWrap rgArrPart2">
                                        <input
                                          type="button"
                                          name="ctl00$MainContent$RadGrid1$ctl00$ctl02$ctl01$ctl18"
                                          value=" "
                                          title="Next Page"
                                          className="rgPageNext"
                                          onClick={() => {
                                            if (pages.length > currentPage) {
                                              setCurrentPage(currentPage + 1);
                                            }
                                          }}
                                        />
                                        <input
                                          type="button"
                                          name="ctl00$MainContent$RadGrid1$ctl00$ctl02$ctl01$ctl19"
                                          value=" "
                                          title="Last Page"
                                          className="rgPageLast"
                                          onClick={() =>
                                            setCurrentPage(pages.length)
                                          }
                                        />
                                      </div>
                                      <div className="rgWrap rgAdvPart">
                                        <span
                                          id="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl01_ChangePageSizeLabel"
                                          className="rgPagerLabel"
                                        >
                                          Page size:
                                        </span>
                                        <div
                                          id="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl01_PageSizeComboBox"
                                          className="RadComboBox RadComboBox_Black"
                                          style={{
                                            width: '46px',
                                            whiteSpace: 'normal',
                                          }}
                                          ref={
                                            dropRef as React.RefObject<HTMLDivElement>
                                          }
                                        >
                                          <table
                                            summary="Page Size Drop Down Control"
                                            style={{
                                              borderWidth: '0',
                                              borderCollapse: 'collapse',
                                              width: '100%',
                                            }}
                                          >
                                            <caption>PageSizeComboBox</caption>
                                            <tbody>
                                              <tr className="rcbReadOnly">
                                                <td
                                                  className="rcbInputCell rcbInputCellLeft"
                                                  style={{ width: '100%' }}
                                                >
                                                  <input
                                                    name="ctl00$MainContent$RadGrid1$ctl00$ctl02$ctl01$PageSizeComboBox"
                                                    type="text"
                                                    className="rcbInput radPreventDecorate"
                                                    id="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl01_PageSizeComboBox_Input"
                                                    value={pageSize}
                                                    onClick={() =>
                                                      setIsOpen(isOpen !== true)
                                                    }
                                                  />
                                                </td>
                                                <td className="rcbArrowCell rcbArrowCellRight">
                                                  <a
                                                    id="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl01_PageSizeComboBox_Arrow"
                                                    style={{
                                                      overflow: 'hidden',
                                                      display: 'block',
                                                      position: 'relative',
                                                      outline: 'none',
                                                    }}
                                                    onClick={() =>
                                                      setIsOpen(isOpen !== true)
                                                    }
                                                  >
                                                    select
                                                  </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <div
                                            className="rcbSlide"
                                            style={{
                                              zIndex: '6000',
                                              display:
                                                isOpen === true
                                                  ? 'block'
                                                  : 'none',
                                            }}
                                          >
                                            <div
                                              id="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl01_PageSizeComboBox_DropDown"
                                              className="RadComboBoxDropDown RadComboBoxDropDown_Black "
                                            >
                                              <div className="rcbScroll rcbWidth">
                                                <ul className="rcbList">
                                                  <li
                                                    className="rcbItem"
                                                    onClick={() => {
                                                      setPageSize(10);
                                                      var pages = [];
                                                      for (
                                                        let i = 0;
                                                        i < tableData.length;
                                                        i += 10
                                                      ) {
                                                        const page =
                                                          tableData.slice(
                                                            i,
                                                            i + 10,
                                                          );
                                                        pages.push(page);
                                                      }
                                                      console.log(pages);
                                                      setPages([...pages]);
                                                      setIsOpen(false);
                                                    }}
                                                  >
                                                    10
                                                  </li>
                                                  <li
                                                    className="rcbItem"
                                                    onClick={() => {
                                                      setPageSize(20);
                                                      var pages = [];
                                                      for (
                                                        let i = 0;
                                                        i < tableData.length;
                                                        i += 20
                                                      ) {
                                                        const page =
                                                          tableData.slice(
                                                            i,
                                                            i + 20,
                                                          );
                                                        pages.push(page);
                                                      }
                                                      setPages([...pages]);
                                                      setIsOpen(false);
                                                    }}
                                                  >
                                                    20
                                                  </li>
                                                  <li
                                                    className="rcbItem"
                                                    onClick={() => {
                                                      setPageSize(50);
                                                      var pages = [];
                                                      for (
                                                        let i = 0;
                                                        i < tableData.length;
                                                        i += 50
                                                      ) {
                                                        const page =
                                                          tableData.slice(
                                                            i,
                                                            i + 50,
                                                          );
                                                        pages.push(page);
                                                      }
                                                      setPages([...pages]);
                                                      setIsOpen(false);
                                                    }}
                                                  >
                                                    50
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <input
                                            id="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl01_PageSizeComboBox_ClientState"
                                            name="ctl00_MainContent_RadGrid1_ctl00_ctl02_ctl01_PageSizeComboBox_ClientState"
                                            type="hidden"
                                          />
                                        </div>
                                      </div>
                                      <div className="rgWrap rgInfoPart">
                                        <strong>{tableData.length} </strong>
                                        items in { }
                                        <strong>{pages.length}</strong> pages
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </thead>
                        <tbody style={{ display: 'none' }}>
                          <tr>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="rgHeaderWrapper">
                        <div
                          id="ctl00_MainContent_RadGrid1_GridHeader"
                          className="rgHeaderDiv"
                          style={{ overflow: 'hidden' }}
                        >
                          <table
                            className="rgMasterTable rgClipCells rgClipCells"
                            id="ctl00_MainContent_RadGrid1_ctl00_Header"
                            style={{
                              fontSize: '12px',
                              width: '100%',
                              tableLayout: 'fixed',
                              overflow: 'hidden',
                              emptyCells: 'show',
                            }}
                          >
                            <colgroup></colgroup>
                            <thead>
                              <tr>
                                {columnData.map((item: any) => (
                                  <th
                                    scope="col"
                                    className="rgHeader"
                                    key={item.n}
                                    style={{
                                      fontSize: '12px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <a style={{
                                      cursor: 'pointer',
                                    }}
                                      title="Click here to sort"
                                      onClick={() => {
                                        sortData(item.n);
                                      }}
                                    >
                                      {item.d}
                                    </a>
                                  </th>
                                ))}
                              </tr>
                              <tr className="rgFilterRow">
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                              </tr>
                            </thead>
                            <tbody style={{ display: 'none' }}>
                              <tr>
                                <td colSpan={parseInt('14')}></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        id="ctl00_MainContent_RadGrid1_GridData"
                        className="rgDataDiv"
                        style={{
                          overflowX: 'auto',
                          overflowY: 'auto',
                          width: '100%',
                          height: '400px',
                        }}
                      >

                        {
                          (!tableData.length) && (
                            <CircularProgress />
                          )
                        }


                        <table
                          className="rgMasterTable rgClipCells rgClipCells"
                          id="ctl00_MainContent_RadGrid1_ctl00"
                          style={{
                            fontSize: '12px',
                            width: '100%',
                            tableLayout: 'fixed',
                            overflow: 'hidden',
                            emptyCells: 'show',
                          }}
                        >
                          <colgroup></colgroup>
                          <thead style={{ display: 'none' }}>
                            <tr>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData
                              .slice(
                                (currentPage - 1) * pageSize,
                                currentPage * pageSize,
                              )
                              .map((item: any, index: any) => (
                                <tr
                                  className={`rgRow ${index % 2 !== 0 ? 'rgAltRow' : ''
                                    }`}
                                  key={item.branch}
                                  id="ctl00_MainContent_RadGrid1_ctl00__0"
                                >
                                  <td
                                    align="left"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.branch}
                                  </td>

                                  <td
                                    align="left"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.email}
                                  </td>

                                  <td
                                    align="left"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.linkID}
                                  </td>
                                  <td
                                    align="left"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.businessName}&nbsp;
                                  </td>
                                  <td
                                    align="left"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.lastName}
                                  </td>
                                  <td
                                    align="left"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.firstName}
                                  </td>
                                  <td
                                    align="center"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.total}&nbsp;
                                  </td>
                                  <td
                                    align="center"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.wave1}&nbsp;
                                  </td>
                                  <td
                                    align="center"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.wave2} &nbsp;
                                  </td>
                                  <td
                                    align="center"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.wave3} &nbsp;
                                  </td>
                                  <td
                                    align="center"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.wave4}&nbsp;
                                  </td>
                                  <td
                                    align="left"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.country}
                                  </td>
                                  <td
                                    align="left"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.stateOrRegion}
                                  </td>
                                  <td
                                    align="center"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {formatDate(item.signUpDate)}
                                  </td>
                                  <td
                                    align="left"
                                    style={{ fontFamily: 'Arial' }}
                                  >
                                    {item.referredBy}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>{' '}
                      </div>
                      {/* <table
                        className="rgMasterTable rgClipCells rgClipCells"
                        id="ctl00_MainContent_RadGrid1_ctl00_Pager"
                        style={{
                          fontSize: '12px',
                          width: '100%',
                          tableLayout: 'fixed',
                          overflow: 'hidden',
                          emptyCells: 'show',
                        }}
                      >
                        <thead style={{ display: 'none' }}>
                          <tr>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table> */}
                      <div
                        id="ctl00_MainContent_RadGrid1_SharedCalendarContainer"
                        style={{ display: 'none' }}
                      ></div>
                      {/* <div id="ctl00_MainContent_RadGrid1_rfltMenu">
                        <div className="RadMenu RadMenu_Black RadMenu_Context RadMenu_Black_Context">
                          <ul className="rmActive rmVertical rmGroup rmLevel1">
                            <li className="rmItem rmFirst">
                              <a
                                className="rmLink"
                                href="https://freedomplaceweb.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">NoFilter</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplaceweb.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">Contains</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplaceweb.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">DoesNotContain</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">StartsWith</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">EndsWith</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">EqualTo</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">NotEqualTo</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">GreaterThan</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">LessThan</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">
                                  GreaterThanOrEqualTo
                                </span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">
                                  LessThanOrEqualTo
                                </span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">Between</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">NotBetween</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">IsEmpty</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">NotIsEmpty</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">IsNull</span>
                              </a>
                            </li>
                            <li className="rmItem ">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">NotIsNull</span>
                              </a>
                            </li>
                            <li className="rmItem rmLast">
                              <a
                                className="rmLink"
                                href="https://freedomplace.azurewebsites.net/system/admin/AdminDashboard#"
                              >
                                <span className="rmText">Custom</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <input
                          id="ctl00_MainContent_RadGrid1_rfltMenu_ClientState"
                          name="ctl00_MainContent_RadGrid1_rfltMenu_ClientState"
                          type="hidden"
                        />
                      </div> */}
                      <input
                        id="ctl00_MainContent_RadGrid1_ClientState"
                        name="ctl00_MainContent_RadGrid1_ClientState"
                        type="hidden"
                      />{' '}
                    </div>
                  </div>
                  <div
                    id="MainContent_RadAjaxLoadingPanel1"
                    style={{ display: 'none' }}
                  >
                    <img
                      alt="Loading..."
                      src="./Freedom Place_ Admin Dashboard_files/loading3.gif"
                      style={{ border: '0' }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <h5>
                    <span
                      id="MainContent_ErrorMessage"
                      className="label-danger"
                    ></span>
                    <span
                      id="MainContent_lblYourLink"
                      style={{ color: 'Black', fontSize: '1px' }}
                    ></span>
                  </h5>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminScreen;
