import { Grid, Stack, Typography } from "@mui/material";

const Logo = () => {

    return (
        <>
            <Stack style={{
                marginBottom: '20px',
            }}>
                <Grid container spacing={2} justifyContent="center" className="m-0 w-100">
                    <img alt="logo" src="/assets/img/star.png" style={{
                        display: 'block',
                        width: '30%',
                    }} />
                    <img alt="logo" src="/assets/img/text.png" style={{
                        display: 'block',
                        width: '80%',
                    }} />
                </Grid>

            </Stack>
        </>
    );
};

export { Logo };