import { Box, MenuItem, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { TabSignUp } from "./tab-sign-up";
import { TabMoney } from "./tab-money";
import { TabProduct } from "./tab-product";
import { TabGift } from "./tab-gift";
import { TabDeal } from "./tab-deal";
import { TabSub } from "./tab-sub";
import { DateTextBox, TextBox } from "@app/components/common/text-box";
import { Strings } from "@app/constants";

const WaveTab = (props: any) => {

    const user = props.user;
    console.log('WaveTab', props);
    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [valueTab, setValueTab] = useState(0);
    //const [selectDate, setselectDate] = useState('30');
    const [selectDate, setselectDate] = useState('all');
    /* const [selectTab, setTab] = useState(Strings.dashboard.signUps);

    function handleTabs(tabName: any): void {
        setTab(tabName);
    } */

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    return (
        <>
            {/* Desktop */}

            <div className="d-none d-md-block w-100">
                <div className="text-center mt-4" style={{
                    fontFamily: 'Cursed Timer ULiL',
                    fontSize: '1.5em',
                }}>
                    SCOREBOARD
                </div>

                <Stack sx={{
                    backgroundColor: '#212B36',
                    width: '100%',
                    borderRadius: '20px',
                    padding: '30px',
                    paddingTop: '10px',
                    marginTop: '20px',
                }}>


                    <Box sx={{ width: '100%' }}>
                        <Box sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            position: 'relative',
                        }}
                        >
                            <Tabs value={valueTab} onChange={handleChangeTab}
                                aria-label="basic tabs example"
                                textColor="inherit"
                            >
                                <Tab label="Sign-Ups" {...a11yProps(0)} sx={{
                                    textTransform: 'none',
                                }} />
                                <Tab label="All Money Earned" {...a11yProps(1)} sx={{
                                    textTransform: 'none',
                                }} />
                                <Tab label="Paid Subscriptions" {...a11yProps(2)} sx={{
                                    textTransform: 'none',
                                }} />
                                <Tab label="Gift Cards" {...a11yProps(3)} sx={{
                                    textTransform: 'none',
                                }} />
                                <Tab label="Deals" {...a11yProps(4)} sx={{
                                    textTransform: 'none',
                                }} />
                                <Tab label="Products" {...a11yProps(5)} sx={{
                                    textTransform: 'none',
                                }} />
                            </Tabs>

                            <DateTextBox variant="outlined" select
                                sx={{
                                    position: 'absolute !important',
                                    right: '0px',
                                    top: '0px',
                                    minWidth: '100px',
                                    height: '20px !important',
                                }}

                                value={selectDate}
                                onChange={(event) => {

                                    setselectDate(event.target.value);
                                    console.log('selectDate', event.target.value);
                                }}
                            >
                                <MenuItem key="all" value="all">
                                    All Time
                                </MenuItem>

                                <MenuItem key="30" value="30">
                                    Last 30 Days
                                </MenuItem>

                                <MenuItem key="60" value="60">
                                    Last 60 Days
                                </MenuItem>

                                <MenuItem key="90" value="90">
                                    Last 90 Days
                                </MenuItem>
                            </DateTextBox>
                        </Box>

                        <TabPanel value={valueTab} index={0}>
                            <TabSignUp data={user.waveMetricsModels} selectDate={selectDate} />
                        </TabPanel>

                        <TabPanel value={valueTab} index={1}>
                            <TabMoney data={user.waveMetricsModels} selectDate={selectDate} user={user} />
                        </TabPanel>

                        <TabPanel value={valueTab} index={2}>
                            <TabSub data={user.waveMetricsModels} selectDate={selectDate} />
                        </TabPanel>

                        <TabPanel value={valueTab} index={3}>
                            <TabGift data={user.waveMetricsModels} selectDate={selectDate} />
                        </TabPanel>

                        <TabPanel value={valueTab} index={4}>
                            <TabDeal data={user.waveMetricsModels} selectDate={selectDate} />
                        </TabPanel>

                        <TabPanel value={valueTab} index={5}>
                            <TabProduct data={user.waveMetricsModels} selectDate={selectDate} />
                        </TabPanel>
                    </Box>
                </Stack>
            </div>

            {/* End Desktop */}

            {/* Mobile */}

            <div className="d-block d-md-none w-100">

                <div className="text-center mt-4" style={{
                    fontFamily: 'Cursed Timer ULiL',
                    fontSize: '1.5em',
                }}>
                    SCOREBOARD
                </div>

                <Stack
                    sx={{
                        backgroundColor: '#212B36',
                        width: '100%',
                        borderRadius: '20px',
                        padding: '10px',
                        marginTop: '20px',
                    }}
                >
                    <DateTextBox variant="outlined" select className="mb-2"
                        sx={{
                        }}

                        value={selectDate}
                        onChange={(event) => {

                            setselectDate(event.target.value);
                            console.log('selectDate', event.target.value);
                        }}
                    >
                        <MenuItem key="all" value="all">
                            All Time
                        </MenuItem>

                        <MenuItem key="30" value="30">
                            Last 30 Days
                        </MenuItem>

                        <MenuItem key="60" value="60">
                            Last 60 Days
                        </MenuItem>

                        <MenuItem key="90" value="90">
                            Last 90 Days
                        </MenuItem>
                    </DateTextBox>

                    <TextBox variant="outlined" select
                        sx={{
                            marginBottom: '40px',
                        }}

                        value={valueTab}
                        onChange={(event: any) => {
                            setValueTab(event.target.value);
                            console.log('tab change', event.target.value);
                        }}
                    >
                        <MenuItem key="0" value="0">
                            Sign-Ups
                        </MenuItem>

                        <MenuItem key="1" value="1">
                            All Money Earned
                        </MenuItem>

                        <MenuItem key="2" value="2">
                            Paid Subscriptions
                        </MenuItem>

                        <MenuItem key="3" value="3">
                            Gift Cards
                        </MenuItem>

                        <MenuItem key="4" value="4">
                            Deals
                        </MenuItem>

                        <MenuItem key="5" value="5">
                            Products
                        </MenuItem>
                    </TextBox>

                    {
                        valueTab == 0 && (
                            <TabSignUp data={user.waveMetricsModels} selectDate={selectDate} style={{
                                marginLeft: '0px',
                                marginRight: '0px',
                            }} />
                        )
                    }

                    {
                        valueTab == 1 && (
                            <TabMoney data={user.waveMetricsModels} selectDate={selectDate}  user={user}/>
                        )
                    }

                    {
                        valueTab == 2 && (
                            <TabSub data={user.waveMetricsModels} selectDate={selectDate} />
                        )
                    }

                    {
                        valueTab == 3 && (
                            <TabGift data={user.waveMetricsModels} selectDate={selectDate} />
                        )
                    }

                    {
                        valueTab == 4 && (
                            <TabDeal data={user.waveMetricsModels} selectDate={selectDate} />
                        )
                    }

                    {
                        valueTab == 5 && (
                            <TabProduct data={user.waveMetricsModels} selectDate={selectDate} />
                        )
                    }

                </Stack>

            </div>

            {/* End Mobile */}
        </>
    );
}
export { WaveTab }