const Video = () => {
    return (
        <>
            <video playsInline autoPlay muted loop
                style={{
                    height: '100%',
                    objectFit: 'fill',
                    position: 'absolute',
                }}
            >
                <source src="/assets/videos/male-mountain.mp4" type='video/mp4' />

            </video>
        </>
    );
}
export { Video }