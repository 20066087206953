/**
 * Freedom Place
 * Initialize redux store, routes, configs
 */

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material';
import './index.css';

import '@app/assets/css/global.scss';
import { Navigator } from '@app/routes';
import { configureStore } from '@app/redux';
import { defaultTheme } from '@app/styles';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';

const { persistor, store } = configureStore();

const Main: React.FC = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ThemeProvider theme={defaultTheme}>
              <SnackbarProvider>
                <Navigator />
              </SnackbarProvider>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>

  );
};

export { Main };
