

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.scss';
import { APP_ROUTE, Strings } from '@app/constants';
import { useDispatch } from 'react-redux';
import { setPassword } from '../slice';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { Video } from '@app/components/common/video';
import { SmallContainer } from '@app/components/common/small-container';
import { Logo } from '@app/components/common/logo';
import Input from '@app/components/common/Input';
import { TextBox } from '@app/components/common/text-box';
import { LoadingButton } from '@mui/lab';
import { Footer } from '@app/components/common/footer';
import Iconify from '@app/components/common/iconify/Iconify';

const FReset = () => {
    const [isRequestSent, setIsRequestSent] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPasswords] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    function goToLogin() {
        navigate(APP_ROUTE.LOGIN);
    }
    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: any) => {
        setPasswords(event.target.value);
    };

    const handleConfirmPassChange = (event: any) => {
        setConfirmPassword(event.target.value);
    };

    function checkValidation() {
        /* const emailRegex = /^\w+([.-]?\w+)*(\+\w+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Email is required.');
            return;
        } else {
            setEmailError('');
        } */

        let isValid = true;

        if (!password || !password.length) {
            setPasswordError('Password is required.');
            isValid = false;
        }
        else {
            setPasswordError('');
        }

        if (!confirmPassword || !confirmPassword.length) {
            setConfirmPasswordError('Confirm password is required.');
            isValid = false;
        }
        else {
            setConfirmPasswordError('');
        }

        if (!isValid) return;

        setIsRequestSent(false);
        // var resetUserId = '';
        // navigate('/verification');
        console.log(isLoading);
        dispatch(
            setPassword({
                password,
                confirmPassword,
                resetUserId: token !== null ? token : '',
                setEmailError,
                navigate,
                setisLoading,
            }),
        );
    }

    useEffect(() => {
        if (token === undefined || token == null) {
            navigate(APP_ROUTE.SESSIONERROR);
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Reset Password - Freedom Place</title>
                <style>
                    {
                        'body { background-color: black; }'
                    }
                </style>
            </Helmet>

            <Container sx={{
                paddingBottom: '40px',
                maxWidth: '1400px !important',
                minHeight: '100vh !important',
                position: 'relative',
                overflow: 'hidden',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
            }}>

                <Video />

                <Grid container justifyContent="center" sx={{
                    width: '100%',
                }}>

                    <SmallContainer>
                        <Logo />

                        <Grid container spacing={2} justifyContent="center" sx={{
                            padding: '30px',
                        }}>
                            <h5 className="d-block d-md-none mb-4" style={{
                                fontSize: '22px',
                                marginTop: '20px',
                            }}>Set New Password</h5>

                            <Typography variant="h4" marginTop="30px" marginBottom="20px" className='d-none d-md-block'>Set New Password</Typography>

                            <Typography variant="body1" sx={{
                                color: 'gray',
                            }}>
                                Enter your new password to login to the application
                            </Typography>
                        </Grid>

                        {/* Password */}

                        <Stack className='w-100'>
                            <Input
                                label="Password" type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} color="white" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                value={password}
                                onChange={handlePasswordChange}
                            >
                            </Input>

                            {
                                passwordError.length > 0 && (
                                    <div className='alert alert-danger'>{passwordError}</div>
                                )
                            }
                        </Stack>

                        {/* End Password */}

                        {/* Confirm Password */}

                        <Stack className='w-100 mt-3'>
                            <TextBox label="Confirm Password" type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} color="white" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                value={confirmPassword}
                                onChange={handleConfirmPassChange}
                            >

                            </TextBox>

                            {
                                confirmPasswordError.length > 0 && (
                                    <div className='alert alert-danger'>{confirmPasswordError}</div>
                                )
                            }
                        </Stack>

                        {/* End Confirm Password */}

                        <div className='row w-100 mt-3'>
                            <LoadingButton loading={isLoading} loadingPosition='end' variant="contained" className='w-100'
                                onClick={checkValidation}
                                sx={{
                                    backgroundColor: '#F9213B',
                                    fontSize: '1em',
                                }}
                            >
                                <span>Update Password</span>
                            </LoadingButton>
                        </div>

                    </SmallContainer>

                </Grid>

                <Footer />

            </Container>
        </>
    );
};

export default FReset;
