/**
 * Auth Slice
 * @format
 */

import { createAction } from '@reduxjs/toolkit';

import { type UserProfile, type ChangePassword } from './types';

// Actions )-------------------------------------
export const updateUserProfile = createAction<UserProfile>(
  'USER/UPDATE_PROFILE',
);

// Actions )-------------------------------------
export const getProfile = createAction<any>('USER/GET_PROFILE');
export const getUserWaves = createAction<any>('USER/GET_WAVES');

export const updateInitialState = createAction<any>('USER/UPDATE_INIT');

export const changePassword = createAction<ChangePassword>(
  'USER/CHANGE_PASSWORD',
);
