/**
 * Root Navigation
 */

import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { APP_ROUTE } from '@app/constants';
import { ChangePasswordScreen } from '@app/modules/change-password';
import SessionOutScreen from '@app/modules/session-out/view/session-out';
import { SessionErrorScreen } from '@app/modules/session-error';
import { FSignUpScreen } from '@app/modules/signup';
import { PostSignupScreen } from '@app/modules/post-sign-up';
import { api } from '@app/api';
import { useSelector } from 'react-redux';
import { FLoginScreen } from '@app/modules/auth';
import FVerificationScreen from '@app/modules/auth/views/f-verification';
import FConsumerDashboardScreen from '@app/modules/dashboard/view/f-consumer-dashboard';
import FProfileScreen from '@app/modules/profile/f-profile';
import FForget from '@app/modules/auth/views/f-forget';
import FReset from '@app/modules/auth/views/f-reset';
import AdminScreen from '@app/modules/admin/admin';
import SignUpLinkScreen from '@app/modules/signup-link/signup-link';

const Navigator: React.FC = () => {
  const selector = useSelector((state: any) => state.user);
  
  useEffect(() => {
    api.setToken(selector.token);
  }, []);
  return (
    <Routes>
      <Route
        path={APP_ROUTE.ROOT}
        element={<Navigate replace to={APP_ROUTE.LOGIN} />}
      />
      <Route
        path={APP_ROUTE.LOGIN}
        element={<FLoginScreen />}
        errorElement={<SessionErrorScreen />}
      />
      <Route path={APP_ROUTE.VERIFICATION} element={<FVerificationScreen />} />
      <Route
        path={APP_ROUTE.DASHBOARDS.USER}
        element={<FConsumerDashboardScreen />}
      />
      <Route
        path={APP_ROUTE.DASHBOARDS.BUSINESS}
        element={<FConsumerDashboardScreen />}
      />
      <Route
        path={APP_ROUTE.DASHBOARDS.NONPROFIT}
        element={<FConsumerDashboardScreen />}
      />
      <Route path={APP_ROUTE.DASHBOARDS.ADMIN} element={<AdminScreen />} />
      <Route path={APP_ROUTE.PROFILES.USER} element={<FProfileScreen />} />
      <Route path={APP_ROUTE.PROFILES.NONPROFIT} element={<FProfileScreen />} />
      <Route path={APP_ROUTE.PROFILES.BUSINESS} element={<FProfileScreen />} />
      <Route
        path={APP_ROUTE.CHANGEPASSWORD}
        element={<ChangePasswordScreen />}
        errorElement={<SessionErrorScreen />}
      />
      <Route
        path={APP_ROUTE.FORGET}
        element={<FForget />}
        errorElement={<SessionErrorScreen />}
      />
      <Route
        path={APP_ROUTE.RESETPASSWORD}
        element={<FReset />}
        errorElement={<SessionErrorScreen />}
      />
      <Route path={APP_ROUTE.SESSIONOUT} element={<SessionOutScreen />} />
      <Route path={APP_ROUTE.POSTSIGNUP} element={<PostSignupScreen />} />
      <Route path={APP_ROUTE.SESSIONERROR} element={<SessionErrorScreen />} />
      <Route path={APP_ROUTE.SIGNUPS.USER} element={<FSignUpScreen />} />
      <Route
        path={APP_ROUTE.SIGNUPS.BUSINESS}
        element={<FSignUpScreen />}
      />
      <Route
        path={APP_ROUTE.SIGNUPS.NONPROFIT}
        element={<FSignUpScreen />}
      />
      <Route
        path={APP_ROUTE.SIGNUPLINKREQUIRED}
        element={<SignUpLinkScreen />}
      />
    </Routes>
  );
};

export { Navigator };
