/**
 * App Configurations
 * base url, libraries keys, env
 */

export const ENV = process.env.NODE_ENV;
console.log('ENV', ENV)
export const BASE_URL = 'https://freedom-place-api.azurewebsites.net/api';
//export const BASE_URL = 'https://freedomwebapi.azurewebsites.net/api';
//export const BASE_URL = 'https://localhost:7170/api';
console.log('BASE_URL', BASE_URL)
export const API_TIMEOUT = 100000;
export const IS_PROD = false;
