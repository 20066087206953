//import { Grid, Stack } from "@mui/material"
import { Stack } from "@mui/material"
import { FaYoutube } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Wave } from "./wave";
import { Link } from "react-router-dom";

const TabSignUp = (props: any) => {
    console.log('TabSignUp', props);

    function signUpValue(): number {
        let d = props.selectDate;
        console.log('TabSignUp.signUpValue(): d = '+ d);
        if (d == '30') return props.data?.signUpsPast30;
        if (d == '60') return props.data?.signUpsPast60;
        if (d == '90') return props.data?.signUpsPast90;
        //if (d == 'all') return props.data?.signUpsPast90;//return props.data?.signUpsAll;
        if (d == 'all') return props.data?.signUpsToday;//return props.data?.signUpsAll;

        return 0;
    }

    function wave1(): number {
        let d = props.selectDate;
        console.log('TabSignUp.wave1(): d = '+ d);
        if (d == '30') return props.data?.wave1_Past30;
        if (d == '60') return props.data?.wave1_Past60;
        if (d == '90') return props.data?.wave1_Past90;
        //if (d == 'all') return props.data?.wave1_All;
        if (d == 'all') return props.data?.wave1_Today;

        return 0;
    }

    function wave2(): number {
        let d = props.selectDate;
        console.log('TabSignUp.wave2(): d = '+ d);
        if (d == '30') return props.data?.wave2_Past30;
        if (d == '60') return props.data?.wave2_Past60;
        if (d == '90') return props.data?.wave2_Past90;
        //if (d == 'all') return props.data?.wave2_Past90;//return props.data?.wave2_All;
        if (d == 'all') return props.data?.wave2_Today;//return props.data?.wave2_All;

        return 0;
    }

    function wave3(): number {
        let d = props.selectDate;
        console.log('TabSignUp.wave3(): d = '+ d);
        if (d == '30') return props.data?.wave3_Past30;
        if (d == '60') return props.data?.wave3_Past60;
        if (d == '90') return props.data?.wave3_Past90;
        //if (d == 'all') return props.data?.wave3_Past90;//return props.data?.wave3_All;
        if (d == 'all') return props.data?.wave3_Today;//return props.data?.wave3_All;

        return 0;
    }

    function wave4(): number {
        let d = props.selectDate;
        console.log('TabSignUp.wave4(): d = '+ d);
        if (d == '30') return props.data?.wave4_Past30;
        if (d == '60') return props.data?.wave4_Past60;
        if (d == '90') return props.data?.wave4_Past90;
        //if (d == 'all') return props.data?.wave4_Past90;//return props.data?.wave4_All;
        if (d == 'all') return props.data?.wave4_Today;//return props.data?.wave4_All;

        return 0;
    }

    function w(): number {
        var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
        return width;
    }

    function md(): boolean {
        return w() >= 992;
    }

    const ContentMobile = (props: any) => {
        return (
            <>
                <div className="row d-block d-md-none" style={{
                    backgroundColor: 'white',
                    marginRight: '10px',
                    marginLeft: '10px',
                    marginTop: '20px',
                    padding: '15px',
                    border: '1 px solid gray',
                    borderRadius: '20px',
                }}>
                    <div className="d-flex justify-content-center">
                        <div style={{
                            fontFamily: 'Cursed Timer ULiL',
                            backgroundColor: '#212B36',
                            padding: '5px',
                            fontSize: '2em',
                            borderRadius: '10px',
                            color: 'white',
                            minWidth: '100px',
                            maxWidth: '200px',
                            textAlign: 'center',
                        }}>
                            {signUpValue()}
                        </div>
                    </div>


                    <h5 className="font-weight-bold text-center">Total Freedom Partners Sign-Ups</h5>
                    {/*<div className="text-center">Consumer members pay you $1 per subscription per month per wave</div>*/}

                    <Stack direction="row"
                        sx={{
                            backgroundColor: '#DFE3E8',
                            borderRadius: '10px',
                            padding: '10px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>

                        <Link to="https://youtu.be/GcVI69q-3Hs" target='_blank' style={{
                            color: 'white',
                            paddingLeft: '10px'
                        }}>
                            <span className="mr-2" style={{ color: 'black' }}>Learn More</span>

                            <FaYoutube size={24} color="#FC0D1B" />
                        </Link>


                    </Stack>

                    <div className="row mt-4 mb-4">
                        <Wave label="Wave 1" value={wave1()} />
                        <div className="w-100 d-block d-md-none"></div>
                        <Wave label="Wave 2" value={wave2()} />
                        <div className="w-100 d-block d-md-none"></div>
                        <Wave label="Wave 3" value={wave3()} />
                        <div className="w-100 d-block d-md-none"></div>
                        <Wave label="Wave 4" value={wave4()} />
                    </div>
                </div>
            </>
        );
    }

    const Content = (props: any) => {
        return (
            <>
                <div className="row" style={{
                    backgroundColor: 'white',
                    marginRight: '30px',
                    marginLeft: '30px',
                    marginTop: '20px',
                    padding: '15px',
                    border: '1 px solid gray',
                    borderRadius: '20px',
                }}>

                    <Stack direction="row">
                        <div style={{
                            fontFamily: 'Cursed Timer ULiL',
                            backgroundColor: '#212B36',
                            padding: '5px',
                            fontSize: '2em',
                            borderRadius: '10px',
                            color: 'white',
                            marginRight: '20px',
                            minWidth: '100px',
                            textAlign: 'center',
                        }}>
                            {signUpValue()}
                        </div>

                    </Stack>

                    <div className="col">
                        <Stack>
                            <h5 className="font-weight-bold">Total Freedom Partners Sign-Ups</h5>
                            {/*<div>Consumer members pay you $1 per subscription per month per wave</div>*/}
                        </Stack>
                    </div>

                    <Stack direction="row"
                        sx={{
                            backgroundColor: '#DFE3E8',
                            borderRadius: '10px',
                            padding: '10px',
                            alignItems: 'center',
                        }}>

                        <Link to="https://youtu.be/GcVI69q-3Hs" target='_blank' style={{
                            color: 'white',
                            paddingLeft: '10px'
                        }}>
                            <span className="mr-2" style={{ color: 'black' }}>Learn More</span>

                            <FaYoutube size={24} color="#FC0D1B" />
                        </Link>


                    </Stack>

                </div>

                <div className="row mt-4 mb-4">
                    <Wave label="Wave 1" value={wave1()} />
                    <div className="w-100 d-block d-md-none"></div>
                    <Wave label="Wave 2" value={wave2()} />
                    <div className="w-100 d-block d-md-none"></div>
                    <Wave label="Wave 3" value={wave3()} />
                    <div className="w-100 d-block d-md-none"></div>
                    <Wave label="Wave 4" value={wave4()} />
                </div>
            </>
        );
    }

    return (
        <>
            {/* Desktop */}

            <div className="d-none d-md-block">
                <Stack sx={{
                    backgroundColor: '#F4F6F8',
                    color: 'black',
                    marginLeft: '-52px',
                    marginRight: '-52px',
                    marginBottom: '-52px',
                    marginTop: '-24px',
                    minHeight: '150px',
                    borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '20px',
                }}
                >
                    <Content />

                </Stack>
            </div>

            {/* End Desktop */}

            {/* Mobile */}

            <div className="d-block d-md-none">
                <Stack sx={{
                    backgroundColor: '#F4F6F8',
                    color: 'black',
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginBottom: '0px',
                    marginTop: '-24px',
                    minHeight: '150px',
                    borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '20px',
                    paddingLeft: '10px',
                }}
                >
                    <ContentMobile />
                </Stack>
            </div>

            {/* End Mobile */}
        </>
    );
}
export { TabSignUp }

