/**
 * API Request
 * @format
 */

import axios, { type AxiosInstance, type AxiosError } from 'axios';

import * as Config from '@app/configs';
import { getFallbackData } from './fallback-data';

class Api {
  http: AxiosInstance;
  authToken: string | null = null;

  constructor() {
    this.http = axios.create({
      baseURL: `${Config.BASE_URL}`,
      timeout: Config.API_TIMEOUT,
    });

    this.interceptRequests();
    this.interceptResponse();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  interceptRequests() {
    /**
     * axios request interceptors for debugging
     * and alter request data
     */
    this.http.interceptors.request.use(
      (reqConfig) => {
        if (this.authToken != null) {
          reqConfig.headers.Authorization = `Bearer ${this.authToken}`;
        }
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        console.log(`[Http.Request: ${reqConfig.url}]`, reqConfig);
        return reqConfig;
      },
      async (error) => {
        return await Promise.reject(error);
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  interceptResponse() {
    /**
     * Customize axios success and error
     * data to easily handle them in app
     */
    this.http.interceptors.response.use(
      (response) => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        console.log(`[Http.Response: ${response.config.url}]`, response);
        return response.data;
      },
      (error: AxiosError) => {
        if (!Config.IS_PROD && error.config != null) {
          const fallbackData = getFallbackData(error.config.url);
          return fallbackData ?? Promise.reject(this.handleApiError(error));
        }

        return Promise.reject(this.handleApiError(error));
      },
    );
  }

  // Handling error
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  handleApiError = (error: AxiosError) => {
    try {
      console.log('[Http.Error]', error);

      if (error.response != null) {
        /*
         Able to connect with server, but something
         went wrong and api returned reason for that
       */
        return {
          message: error.response.data,
          status:
            error.response.status !== undefined ? error.response.status : null,
        };
      } else {
        // Not able to connect with server
        return {
          message: error.message,
        };
      }
    } catch {
      // Can't figure out source of error
      return {
        message: 'unknown error occurred',
        status: error.response !== undefined ? error.response.status : null,
      };
    }
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  setToken(token: string | null) {
    this.authToken = token;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getToken() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.authToken;
  }
}

const api = new Api();

const request = api.http;

export { api, request };
