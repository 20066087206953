import React, { useCallback, useEffect, useState } from 'react';
import './auth.module.scss';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTE, Strings } from '@app/constants';
import './login.scss';
import { useDispatch, useSelector } from 'react-redux';
import { verifyCode } from '../slice';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Typography } from '@mui/material';
import { Video } from '@app/components/common/video';
import { Footer } from '@app/components/common/footer';
import { SmallContainer } from '@app/components/common/small-container';
import { Logo } from '@app/components/common/logo';
import { VerifyCodeForm } from '@app/components/common/VerifyForm';
import { FSnack } from '@app/components/common/f-snack';

const FVerificationScreen: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setisLoading] = useState(false);
    const selector = useSelector((state: any) => state.user);
    const [otpError, setOtpError] = useState('');

    useEffect(() => {
        if (selector.secretKey.length <= 0) {
            navigate(APP_ROUTE.LOGIN);
        }
    }, []);

    /* function validate(): void {
        // navigate(APP_ROUTE.DASHBOARDS.USER);
        console.log(isLoading);
        dispatch(
            verifyCode({
                username: selector.username,
                secretKey: selector.secretKey,
                code: otp,
                setOtpError,
                navigate,
                setisLoading,
            }),
        );
    } */

    const showOtpError = (s: string) => {
        setOtpError(s);
        FSnack.showError(s);
    }

    const onPressSubmit = useCallback(
        (code: string) => {
            dispatch(
                verifyCode({
                    username: selector.username,
                    secretKey: selector.secretKey,
                    code: code,
                    setOtpError: showOtpError,
                    navigate,
                    setisLoading,
                }),
            );
        },
        [dispatch]
    );

    return (
        <>
            <Helmet>
                <title>Verification - Freedom Place</title>
                <style>
                    {
                        'body { background-color: black; }'
                    }
                </style>
            </Helmet>

            <Container sx={{
                paddingBottom: '40px',
                maxWidth: '1400px !important',
                minHeight: '100vh !important',
                position: 'relative',
                overflow: 'hidden',
                paddingLeft: '0px !important',
            }}>
                <Video />

                <Grid container justifyContent="center" sx={{
                    width: '100%',
                }}>
                    <SmallContainer>
                        <Logo />

                        <Grid container spacing={2} justifyContent="center" sx={{
                            padding: '30px',
                        }}>
                            <Typography variant="h4" marginTop="30px" marginBottom="20px">Verify Pin</Typography>

                            <Typography variant="body1" sx={{
                                color: 'gray',
                            }}>
                                We've emailed you a six digit verification code. Please enter the code below. If you do not find the email in your inbox, check your spam.
                            </Typography>
                        </Grid>

                        <VerifyCodeForm onPressSubmit={onPressSubmit} loading={isLoading} />

                    </SmallContainer>
                </Grid>

                <Footer />
            </Container>
        </>
    );
};

export default FVerificationScreen;
