/**
 * Api Endpoints
 * @format
 */

export const login = '/Token/auth';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const forget_password = '/Token/ForgotPassword';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const get_profile = '/User/ConsumerProfile';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const get_annoucement = '/User/GetAnnouncement/';

export const check_email = '/User/CheckEmail/';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const set_password = '/Token/SetPassword';
export const register = '/Token/RegisterUser';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const reset_password = '/User/ForgetPassword?LoginId=';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const login_with_phone = '/User/GenerateOTP';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const verify_otp = '/Token/ValidateOTP/';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const get_my_profile = '/User/GetProfile';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const update_profile = '/User/UpdateProfile';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const initial_profile = '/User/InitialUserProfile';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const change_password = '/User/ChangePassword';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const consumer_singups = '/consumer/singups';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const business_singups = '/business/singups';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const church_singups = '/church/singups';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const user_waves = '/User/LoadProfileDataWave/';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const get_admin_data = '/Admin/GetAllBranchData/';
export const get_admin_data_2 = '/Admin/GetBranchData';
