/* eslint-disable react/no-unknown-property */
import React from 'react';
import './signup-link.scss';
import { Footer } from '@app/components/common/footer';
import { Strings } from '@app/constants';
import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import { SmallContainer } from '@app/components/common/small-container';
import { Logo } from '@app/components/common/logo';

const SignUpLinkScreen: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Error - Freedom Place</title>
        <style>
          {
            'body { background-color: black; }'
          }
        </style>
      </Helmet>

      <Container sx={{
        paddingBottom: '40px',
        maxWidth: '1400px !important',
        position: 'relative',
        overflow: 'hidden',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
      }}>

        {/* <Video /> */}

        <Grid container justifyContent="center" sx={{
          width: '100%',
        }}>

          <SmallContainer>
            <Logo />

            <p style={{ fontWeight: 'bold', color: 'red' }}>
                {Strings.signUpLink.referalCode}
              </p>
          </SmallContainer>

        </Grid>

        <Footer />
      </Container>
    </>

  );
};

export default SignUpLinkScreen;
