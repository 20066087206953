/**
 * Root Reducer
 * combine all reducers to create root reducer
 */

import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import Storage from 'redux-persist/lib/storage';
import { userReducer } from './userReducer';

/* -----[ persist configurations ]------ */
const rootPersistConfig = {
  key: 'root',
  storage: Storage,
  whitelist: [],
  blacklist: ['user'],
};

const userPersistConfig = {
  key: 'user',
  storage: Storage,
  whitelist: ['token', 'secretKey', 'userId', 'linkId', 'role', 'email'],
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
});

const persistRootReducer = persistReducer(rootPersistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export { persistRootReducer };
