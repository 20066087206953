/**
 * Fallback data
 * @format
 */

import { endpoints } from './';

const fallbackData: any = {
  [endpoints.get_my_profile]: null,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getFallbackData = (url: string = '') => {
  return fallbackData[url];
};

export { fallbackData, getFallbackData };
