/**
 * Footer wrapper
 */

import React from 'react';
import { Strings } from '@app/constants';
import './footer.scss';

interface Props {
  children: React.ReactNode;
}

const Footer: React.FC<Props> = (props) => {
  return (
    <footer>
      <div className="right-reserved-text">
        &copy;
        {Strings.footer.rights}
        <br />
        <a
          href="https://freedomplace.com/about/terms"
          target="_blank"
          rel="noreferrer"
          className="text-decoration"
        >
          {Strings.footer.termsOfUse}{' '}
        </a>
        &nbsp;
        <a
          href="https://freedomplace.com/about/privacy"
          target="_blank"
          rel="noreferrer"
          className="text-decoration"
        >
          {Strings.footer.primaryPolicy}{' '}
        </a>
      </div>
    </footer>
  );
};
export default Footer;
